import React, { useState, useRef, useEffect } from "react";
import {
  Modal,
  Box,
  Grid,
  Theme,
  Button,
  useTheme,
  useMediaQuery,
  Container,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import PinInputField from "../PinInput";
import { useTranslation } from "react-i18next";
import PasswordModal from "../PasswordModal";
import { useVerifyPasswordMutation } from "../../redux/api/companyApi";
import { useAlert } from "../../contexts/AlertContext";
import { useUpdateProgramMutation } from "../../redux/api/programApi";
import { Program } from "../../types";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

interface PasscodeModalProps {
  program: Program | null;
  open: boolean;
  onClose: () => void;
  onPasscodeSubmit: (passcode: string) => void;
  pinLength?: number; // Optional prop with a default value
}

const useStyles = makeStyles((theme: Theme) => ({
  logo: {
    marginTop: theme.spacing(5),
    marginLeft: theme.spacing(5),
    marginBottom: theme.spacing(5),
    [theme.breakpoints.between("xs", "md")]: {
      width: 241,
    },
    [theme.breakpoints.between("md", "xl")]: {
      width: 250,
    },
    [theme.breakpoints.up("xl")]: {
      width: 250,
    },
  },
  heading: {
    margin: 0,
    textAlign: "center",
    color: "#121212",
    [theme.breakpoints.between("xs", "md")]: {
      fontSize: "2.5rem",
    },
    [theme.breakpoints.between("md", "xl")]: {
      fontSize: "3rem",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "4.375rem",
    },
  },
  subheading: {
    textAlign: "center",
    marginBottom: theme.spacing(4),
    color: "#121212",
    [theme.breakpoints.between("xs", "md")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.between("md", "xl")]: {
      fontSize: "1.2rem",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "2rem",
    },
  },
  card: {
    width: "100%", // This makes the card take the full width of the container
    // Using a pseudo-element to create an aspect ratio of 1:1
    "&::before": {
      content: '""',
      display: "block",
      paddingTop: "100%", // This creates the square shape
    },
    position: "relative", // Needed for absolute positioning of the CardMedia
  },
  cardMedia: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    height: "100%", // This will make the CardMedia fill the entire space of the card
    width: "100%",
  },
  cardName: {
    marginTop: theme.spacing(1),
    textAlign: "center", // This will center the text below the image
  },
  addActionCard: {
    width: "100%",
    "&::before": {
      content: '""',
      display: "inline",
      paddingTop: "100%",
    },
    position: "relative",
    textAlign: "center",
  },
  addButton: {
    width: "100%",
    // Center the content
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // Set the height to match other cards (adjust the value as needed)
    height: "100%", // Assuming other cards have a height that fits the content
    border: "1px dashed",
    borderColor: theme.palette.action.disabled,
    borderRadius: theme.shape.borderRadius,
  },
  addIcon: {
    // If you need to adjust the size, set it here
    fontSize: "large", // Adjust size as needed
  },
}));

const PasscodeModal: React.FC<PasscodeModalProps> = ({
  program,
  open,
  onClose,
  onPasscodeSubmit,
  pinLength = 4, // Default pin length is 4 if not specified
}) => {
  const [pins, setPins] = useState(Array(pinLength).fill("")); // Initialize with dynamic length
  const [modalState, setModalState] = useState("enterPasscode"); // Modal state: 'enterPasscode', 'verifyPassword', 'resetPasscode'
  const [password, setPassword] = useState("");
  const pinInputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const classes = useStyles();
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const [isVeerifyPasswordModalOpen, setIsVerifyPasswordModalOpen] =
    useState(false);
  const { error, success } = useAlert();

  const { t } = useTranslation();

  const [verifyPassword] = useVerifyPasswordMutation();
  const [updateProgram, { isLoading: isUpdating }] = useUpdateProgramMutation();

  useEffect(() => {
    if (pins.every((pin) => pin.length === 1)) {
      if (modalState === "enterPasscode") {
        onPasscodeSubmit(pins.join(""));
        setPins(Array(pinLength).fill("")); // Reset with dynamic length
      }
    }
  }, [pins, pinLength, modalState, onPasscodeSubmit]);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const newPins = [...pins];
    newPins[index] = event.target.value.slice(0, 1);
    setPins(newPins);

    // Focus next pin input after current input is filled
    if (index < pinLength - 1 && event.target.value) {
      pinInputRefs.current[index + 1]?.focus();
    }
  };

  const handleBackspace = (index: number) => {
    if (index > 0) {
      const newPins = [...pins];
      newPins[index - 1] = "";
      setPins(newPins);
      pinInputRefs.current[index - 1]?.focus();
    }
  };

  const handlePasswordVerification = async (password: string) => {
    try {
      // Assuming verifyPassword API takes { password } and returns { verified: boolean }
      const response = await verifyPassword({ password }).unwrap();
      if (response.result) {
        success("Password verification successful.");
        setModalState("resetPasscode");
      } else {
        error("Password verification failed.");
      }
    } catch (e) {
      console.error("Failed to verify password:", e);
      error("An error occurred during password verification.");
    }
  };

  const handleUpdatePasscode = async () => {
    if (pins.length !== 4) {
      error("Pin length must be 4");
      return;
    }

    try {
      await updateProgram({
        pin: pins.join(""),
        program_id: program?.id ?? "",
      }).unwrap();
      success("Program Passcode Updated!");
      onClose();
    } catch (e) {
      error("Failed to update program passcode");
    }
  };

  const handleClose = () => {
    setPins(Array(pinLength).fill("")); // Reset with dynamic length
    setPassword("");
    setModalState("enterPasscode");
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="Enter Passcode to access program"
      aria-describedby="This is required to continue to the selected program"
    >
      <Box sx={{ ...style, width: isMdUp ? "auto" : "75%" }}>
        {modalState === "enterPasscode" && (
          <>
            <h1 className={classes.heading}>{t("PasscodeModal.ModalTitle")}</h1>
            <p className={classes.subheading}>
              {t("PasscodeModal.ModalDescription")}
            </p>
            <Container
              sx={{
                mx: "auto",
                width: {
                  xs: "90%", // 100% width on extra-small devices
                  sm: "80%", // 90% width on small devices
                  md: "70%", // 80% width on medium devices
                  lg: "70%", // 70% width on large devices
                  xl: "50%", // 60% width on extra-large devices
                },
              }}
            >
              <Grid container spacing={2} justifyContent="center">
                {pins.map((pin, index) => (
                  <Grid item xs={12 / pinLength} key={`pin-box-${index}`}>
                    <PinInputField
                      isPassword={true}
                      value={pin}
                      handleChange={handleChange}
                      handleBackspace={handleBackspace}
                      index={index}
                      autoFocus={index === 0}
                      ref={(el) => (pinInputRefs.current[index] = el)} // Assign the element to the ref array
                    />
                  </Grid>
                ))}
              </Grid>
            </Container>
            <div
              style={{ textAlign: "center", marginBottom: theme.spacing(4) }}
            >
              <Button
                variant="contained"
                type="button"
                color="primary"
                size="large"
                sx={{
                  width: "fit-content",
                  fontSize: isMdUp ? "1rem" : "inherit",
                  background: "#EC674C",
                  opacity: 1,
                  paddingX: theme.spacing(6),
                  paddingy: theme.spacing(1),
                  mt: theme.spacing(4),
                  textTransform: "none",
                  borderRadius: isMdUp ? "40px" : "30px",
                  "&:hover": {
                    background: "#EC674C", // Change background color on hover
                  },
                }}
                onClick={() => setIsVerifyPasswordModalOpen(true)}
              >
                {t("PasscodeModal.ForgotPasscodeButton")}
              </Button>
            </div>
          </>
        )}

        {modalState === "resetPasscode" && (
          <>
            <h1 className={classes.heading}>
              {t("PasscodeModal.ResetPasscodeTitle")}
            </h1>
            <p className={classes.subheading}>
              {t("PasscodeModal.ResetPasscodeDescription")}
            </p>
            <Container
              sx={{
                mx: "auto",
                width: {
                  xs: "90%", // 100% width on extra-small devices
                  sm: "80%", // 90% width on small devices
                  md: "70%", // 80% width on medium devices
                  lg: "70%", // 70% width on large devices
                  xl: "50%", // 60% width on extra-large devices
                },
              }}
            >
              <Grid container spacing={2} justifyContent="center">
                {pins.map((pin, index) => (
                  <Grid item xs={12 / pinLength} key={`pin-box-${index}`}>
                    <PinInputField
                      value={pin}
                      handleChange={handleChange}
                      handleBackspace={handleBackspace}
                      index={index}
                      autoFocus={index === 0}
                      ref={(el) => (pinInputRefs.current[index] = el)} // Assign the element to the ref array
                    />
                  </Grid>
                ))}
              </Grid>
            </Container>
            <div
              style={{ textAlign: "center", marginBottom: theme.spacing(4) }}
            >
              <Button
                variant="contained"
                type="button"
                color="primary"
                size="large"
                sx={{
                  width: "fit-content",
                  fontSize: isMdUp ? "1rem" : "inherit",
                  background: "#EC674C",
                  opacity: 1,
                  paddingX: theme.spacing(6),
                  paddingy: theme.spacing(1),
                  mt: theme.spacing(4),
                  textTransform: "none",
                  borderRadius: isMdUp ? "40px" : "30px",
                  "&:hover": {
                    background: "#EC674C", // Change background color on hover
                  },
                }}
                onClick={handleUpdatePasscode}
              >
                {t("PasscodeModal.ResetPasscodeButton")}
              </Button>
            </div>
          </>
        )}
        <PasswordModal
          open={isVeerifyPasswordModalOpen}
          onClose={() => setIsVerifyPasswordModalOpen(false)}
          onPasswordSubmit={handlePasswordVerification}
        />
      </Box>
    </Modal>
  );
};

export default PasscodeModal;
