import React from "react";
import { Card, CardMedia, Skeleton, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import useBreakpoints from "../../hooks/useBreakPoints";

const useStyles = makeStyles(() => ({
  card: {
    position: "relative",
    backdropFilter: "blur(0px)",
    WebkitBackdropFilter: "blur(0px)",
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      background:
        "transparent linear-gradient(180deg, #FFFFFF00 0%, #000000 100%)",
      backgroundSize: "cover",
      backgroundPosition: "center",
      zIndex: 0,
    },
  },
  media: {
    height: "100%",
  },
  infoContainer: {
    position: "absolute",
    bottom: 10,
    left: "5%",
    width: "90%",
  },
  progressBar: {
    width: "100%",
    marginTop: 8,
  },
}));

export const ContinueWatchingSkeletonCard = () => {
  const classes = useStyles();
  const { isXs, isSm, isMd, isLg, isXl } = useBreakpoints();

  // Calculates responsive sizing for the card based on breakpoints
  const cardHeight = isXl ? 302 : isLg ? 252 : isMd ? 252 : isSm ? 175 : 175;
  const cardWidth = isXl ? 325 : isLg ? 275 : isMd ? 275 : isSm ? 225 : 225;

  return (
    <Card
      className={classes.card}
      sx={{ width: cardWidth, height: cardHeight, borderRadius: "10px" }}
    >
      <Skeleton variant="rectangular" width="100%" height="100%" />
      <Box className={classes.infoContainer}>
        <Skeleton variant="text" width="60%" height={24} />
        <Skeleton variant="text" width="80%" height={32} />
        <Skeleton
          variant="rectangular"
          className={classes.progressBar}
          height={10}
        />
      </Box>
    </Card>
  );
};
