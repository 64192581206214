import React, { ReactNode } from "react";
import { Swiper } from "swiper/react";
import {
  Navigation,
  Pagination,
  A11y,
  Keyboard,
} from "swiper/modules";

// Importing Swiper styles for navigation, pagination, and scrollbar
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "./css/swiper.css"

/**
 * @description Props interface for SwiperScroll component.
 * @typedef {Object} SwiperScrollProps
 * @property {ReactNode} children - React nodes to be rendered as Swiper slides.
 * @property {number} slidesPerView - Number of slides to show per view.
 */
interface SwiperScrollProps {
  children: ReactNode;
  slidesPerView?: number;
}

/**
 * @description SwiperScroll component providing a customizable Swiper slider.
 * Utilizes the Swiper library for creating responsive and interactive carousels.
 * @see {@link https://swiperjs.com/react | Swiper React Documentation}
 * @param {SwiperScrollProps} props - Props for the SwiperScroll component.
 * @returns {React.ReactElement} - Rendered Swiper component with the provided children as slides.
 */
const SwiperScroll: React.FC<SwiperScrollProps> = ({
  children,
  slidesPerView,
}: SwiperScrollProps): React.ReactElement => {
  return (
    <Swiper
      // Setting the direction of Swiper to horizontal.
      direction="horizontal"
      // Including modules like Navigation, Accessibility, and Keyboard Control.
      modules={[Navigation, A11y, Keyboard]}
      // Space between slides.
      spaceBetween={10}
      // Number of slides per view.
      slidesPerView={"auto"}
      // Enabling keyboard control.
      keyboard={{ enabled: true }}
      // Enabling navigation (next/prev buttons).
      navigation={true}
      // Enabling mousewheel control.
      mousewheel={false}
      // pagination={{enabled: true, clickable: true}}
    >
      {children} {/* Rendering children as individual slides */}
    </Swiper>
  );
};

export default SwiperScroll;
