import React from "react";
import { Box, Skeleton, Theme, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import useBreakpoints from "../../hooks/useBreakPoints";
import ThumbnailSkeleton from "./ThumbnailSkeleton";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: theme.spacing(5),
  },
  skeletonContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

const CategoryListSkeleton = ({ isChild = false }: { isChild: boolean }) => {
  const classes = useStyles();
  const theme = useTheme();

  const { isXs, isSm, isMd, isLg, isXl } = useBreakpoints();

  const numberOfSkeletons = isXs
    ? 1
    : isSm
    ? 3
    : isMd
    ? 3
    : isLg
    ? 4
    : isXl
    ? 4
    : 1;

  return (
    <Box
      className={classes.container}
      style={{
        paddingLeft: isChild
          ? theme.spacing(0)
          : isXs || isSm
          ? theme.spacing(2)
          : theme.spacing(15),
      }}
    >
      <Skeleton variant="rectangular" width="50%" height="50%" />

      <Box className={classes.skeletonContainer}>
        {Array.from({ length: numberOfSkeletons }).map((_, index) => (
          <ThumbnailSkeleton key={index} />
        ))}
      </Box>
    </Box>
  );
};

export default CategoryListSkeleton;
