import React, { useState } from "react";
import {
  Dialog,
  Typography,
  Button,
  Box,
  Chip,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  useTheme,
  Theme,
} from "@mui/material";
import PlayArrowOutlinedIcon from "@mui/icons-material/PlayArrowOutlined";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import useBreakpoints from "../../hooks/useBreakPoints";
import AddToPlaylistModal from "../AddToPlaylistModal";
import { useNavigate } from "react-router";
import { Video } from "../../types";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentVideo } from "../../redux/state/videoSlice";
import { makeStyles } from "@mui/styles";
import { useAlert } from "../../contexts/AlertContext";
import { formatVideoLength } from "../../utils/formatVideoLength";
import { useContinueWatching } from "../../hooks/useContinueWatching";
import { RootState } from "../../redux/store";

const useStyles = makeStyles((theme: Theme) => ({
  // Style for the card container
  card: {
    position: "relative",
    backdropFilter: "blur(0px)",
    WebkitBackdropFilter: "blur(0px)",
    height: "50%",
    overflow: "hidden", // Ensures the blur effect is contained within the card
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      background:
        "transparent linear-gradient(180deg, #FFFFFF00 0%, #000000 100%)",
      backgroundSize: "cover",
      backgroundPosition: "center",
      zIndex: 0,
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
    },
  },
  // Style for the media/image part of the card
  media: {
    height: "100%",
  },
  // Style for the container holding the information
  infoContainer: {
    position: "absolute",
    bottom: 5,
    left: "5%",
    width: "90%",
  },
  // Style for the category chip
  categoryChip: {
    "& .MuiChip-label": {
      color: "#FFFFFF",
      lineHeight: "50px",
      fontFamily: "Roboto",
      letterSpacing: "0px",
    },
  },
  // Style for the length chip
  lengthChip: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
    "& .MuiChip-label": {
      color: "#FFFFFF",
      lineHeight: "50px",
      fontFamily: "Roboto",
      letterSpacing: "0px",
    },
  },
  // Style for the title text
  title: {
    color: "white",
    marginBottom: theme.spacing(1),
  },

  scrollbar: {
    "&::-webkit-scrollbar": {
      display: "none", // Hide the scrollbar for Webkit browsers
    },
    "-ms-overflow-style": "none", // IE and Edge
    "scrollbar-width": "none", // Firefox
    borderRadius: 10,
  },
}));

interface VideoPreviewModalProps {
  open: boolean;
  onClose: () => void;
  video: Video | undefined;
}

const VideoPreviewModal: React.FC<VideoPreviewModalProps> = ({
  open,
  onClose,
  video,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [playlists, setPlaylists] = useState<string[]>([
    "Inspiration Videos",
    "Feel Good Videos",
  ]); // Example playlists

  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { info } = useAlert();
  const { currentProgram } = useSelector((state: RootState) => state.program);
  const { user } = useSelector((state: RootState) => state.user);

  const { features } = user;
  const shouldShowPlaylist = features.playlist;

  const { fetchVideoContinueWatching, create } = useContinueWatching(
    currentProgram.id
  );

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handlePlayVideo = async () => {
    if (video && video.medias.length) {
      try {
        // Fetch the latest video continue watching state
        const { data: updatedData } = await fetchVideoContinueWatching({
          program_id: currentProgram.id,
          video_id: video.id,
          sort: "asc",
          field: "created_at",
          page_number: 1,
          page_size: 1,
        });

        if (updatedData && updatedData.count > 0) {
          const continueWatchingEntry = updatedData.data[0];
          // Set the video with the updated progress
          dispatch(
            setCurrentVideo({
              video,
              programId: continueWatchingEntry.program_id,
              progress: continueWatchingEntry.current_position,
              total: video.medias[0].length,
            })
          );
        } else {
          // If the video is not in the continue watching list, create a new entry
          await create({
            program_id: currentProgram.id,
            video_id: video.id,
            current_position: 0,
            is_complete: false,
          });

          dispatch(
            setCurrentVideo({
              video,
              programId: currentProgram.id,
              progress: 0,
              total: video.medias[0].length,
            })
          );
        }

        navigate(`/browse/stream/${video.id}`);
      } catch (error) {
        console.error("Error handling play video:", error);
      }
    } else {
      info("Video not available");
    }
  };

  const handleMoreInfo = () => {
    if (video) {
      dispatch(setCurrentVideo({ video }));
      navigate(`/browse/more-info/${video.id}`);
    }
  };

  const { isXs, isSm, isMd, isLg, isXl } = useBreakpoints();
  const contentHeight = isXl ? 420 : isLg ? 300 : isMd ? 280 : isSm ? 250 : 240;
  const contentWidth = isXl ? 628 : isLg ? 528 : isMd ? 428 : isSm ? 328 : 228;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={false}
      PaperProps={{
        sx: {
          borderRadius: 10,
        },
      }}
    >
      <Card
        sx={{
          height: "auto",
          width: contentWidth,
          overflowY: "scroll",
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
        }}
        className={classes.scrollbar}
      >
        <div className={classes.card}>
          <CardMedia
            component="img"
            image={video?.thumbnails?.[0]?.url || ""}
            alt={video?.title}
            sx={{
              height: "100%",
              width: "100%",
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
            }}
          />
          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              color: "white",
              padding: theme.spacing(2),
            }}
          >
            <Typography gutterBottom variant="h5" component="div">
              {video?.title}
            </Typography>
            <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
              <Chip
                label={video?.publish_date.slice(0, 4)}
                className={classes.categoryChip}
                sx={{
                  backgroundColor: "#EC674C40",
                  borderRadius: 2,
                }}
              />
              {video.medias && video.medias.length ? (
                video.medias[0].length ? (
                  <Chip
                    label={formatVideoLength(video.medias[0].length)}
                    className={classes.categoryChip}
                    sx={{ backgroundColor: "#EC674C40", borderRadius: 2 }}
                  />
                ) : (
                  ""
                )
              ) : (
                ""
              )}
              {video?.category.map((category, index) => (
                <Chip
                  key={index}
                  label={category}
                  className={classes.categoryChip}
                  sx={{
                    backgroundColor: "#EC674C40",
                    borderRadius: 2,
                  }}
                />
              ))}
            </Box>
          </Box>
        </div>
        <CardContent sx={{ paddingX: theme.spacing(4) }}>
          <Typography gutterBottom variant="body2" component="div">
            {video?.description}
          </Typography>
        </CardContent>
        <CardActions
          sx={{
            flexDirection: "column",
            justifyContent: "space-around",
            alignItems: "center",
            paddingX: theme.spacing(4),
            paddingBottom: theme.spacing(4),
            height: "auto",
            "& > :not(:first-of-type)": {
              marginLeft: 0,
            },
          }}
        >
          <Button
            variant="contained"
            startIcon={<PlayArrowOutlinedIcon fontSize="large" />}
            onClick={handlePlayVideo}
            fullWidth
            sx={{
              mb: theme.spacing(2),
              "&:hover": {
                background: "#EC674C",
                boxShadow: "none",
              },
              boxShadow: "none",
              borderRadius: 38,
              py: theme.spacing(1),
              textTransform: "none",
            }}
          >
            Play Video
          </Button>
          {shouldShowPlaylist && (
            <Button
              variant="outlined"
              startIcon={<PlaylistAddIcon fontSize="large" />}
              onClick={handleOpenModal}
              fullWidth
              sx={{
                mb: theme.spacing(2),
                "&:hover": {
                  boxShadow: "none",
                  background: "none",
                  borderColor: "#707070",
                },
                borderRadius: 38,
                py: theme.spacing(1),
                textTransform: "none",
                color: "#707070",
                borderColor: "#707070",
              }}
            >
              Add to Playlist
            </Button>
          )}

          <Button
            variant="outlined"
            startIcon={<InfoOutlinedIcon fontSize="large" />}
            fullWidth
            onClick={handleMoreInfo}
            sx={{
              "&:hover": {
                boxShadow: "none",
                background: "none",
                borderColor: "#707070",
              },
              borderRadius: 38,
              py: theme.spacing(1),
              textTransform: "none",
              color: "#707070",
              borderColor: "#707070",
            }}
          >
            More Info
          </Button>
        </CardActions>
      </Card>
      <AddToPlaylistModal
        open={isModalOpen}
        onClose={handleCloseModal}
        id={video?.id!}
        _id={video?._id!}
      />
    </Dialog>
  );
};

export default VideoPreviewModal;
