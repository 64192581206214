import { SwiperSlide } from "swiper/react";
import SwiperScroll from "../../../../components/SwiperScroll";
import useBreakpoints from "../../../../hooks/useBreakPoints";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import Thumbnail from "../../../../components/Thumbnail";
import { Video } from "../../../../types";
import { useTranslation } from "react-i18next";
import SliderWrapper from "../../../../components/SliderWrapper";

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(15),
    },
  },
}));

interface SuggestionsProps {
  videos: Video[];
}

const Suggestions = ({ videos }: SuggestionsProps) => {
  const classes = useStyles();
  const { isXs, isSm, isMd, isLg, isXl } = useBreakpoints();
  const { t } = useTranslation();

  const cardWidth = isXl ? 325 : isLg ? 275 : isMd ? 275 : isSm ? 225 : 225;


  const slidesPerView = isXs
    ? 1.5
    : isSm
    ? 3
    : isMd
    ? 3
    : isLg
    ? 4.2
    : isXl
    ? 5
    : 1;

  return (
    <div className={classes.container}>
      <h2 style={{ marginBottom: 2, color: "#121212" }}>
        {t("SearchPage.SuggestionsTitle")}
      </h2>
      {/* <SwiperScroll>
        {videos.map((video) => (
          <SwiperSlide key={video._id} style={{ width: cardWidth }}>
            <Thumbnail videoId={video._id} video={video}/>
          </SwiperSlide>
        ))}
      </SwiperScroll> */}
      <SliderWrapper slidesPerView={slidesPerView}>
        {videos.map((video) => (
          <Thumbnail key={video._id} video={video} videoId={video._id} />
        ))}
      </SliderWrapper>
    </div>
  );
};

export default Suggestions;
