import { ListItemButton } from "@mui/material";
import { styled } from "@mui/system";

// Define the styled component for ListItemButton
export const StyledListItemButton = styled(ListItemButton)(({ theme, selected }) => ({
    border: `1px solid ${theme.palette.divider}`,
    marginBottom: theme.spacing(2), // Add margin bottom to each button
    borderRadius: theme.shape.borderRadius,
    "&.Mui-selected": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      "& .MuiTypography-root": {
        color: theme.palette.common.white,
      },
    },
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
      "&.Mui-selected, &.Mui-selected:hover": {
        backgroundColor: theme.palette.primary.dark,
      },
    },
  }));