import React from "react";
import { Card, Skeleton, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import useBreakpoints from "../../hooks/useBreakPoints";

const useStyles = makeStyles((theme) => ({
  card: {
    position: "relative",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    overflow: "hidden",
  },
  infoContainer: {
    position: "absolute",
    bottom: 5,
    left: "5%",
    width: "90%",
  },
}));

const ThumbnailSkeleton = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { isSm, isMd, isLg, isXl } = useBreakpoints();

  // Calculates responsive sizing for the card based on breakpoints
  const cardHeight = isXl ? 302 : isLg ? 252 : isMd ? 252 : isSm ? 175 : 175;
  const cardWidth = isXl ? 325 : isLg ? 275 : isMd ? 275 : isSm ? 225 : 225;

  return (
    <Card
      className={classes.card}
      sx={{ width: cardWidth, height: cardHeight, borderRadius: "10px" }}
    >
      <Skeleton variant="rectangular" width="100%" height="100%" />
      <Box className={classes.infoContainer}>
        <Skeleton variant="text" width="60%" height={30} />
        <Skeleton variant="text" width="40%" height={20} />
      </Box>
    </Card>
  );
};

export default ThumbnailSkeleton;
