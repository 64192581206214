import React from "react";
import { SwiperSlide } from "swiper/react";
import SwiperScroll from "../SwiperScroll";

interface SliderWrapperProps {
  slidesPerView: number;
  children: React.ReactNode[];
}

const SliderWrapper: React.FC<SliderWrapperProps> = ({
  slidesPerView,
  children,
}) => {

  return (
    <SwiperScroll slidesPerView={slidesPerView}>
      {children.map((child, index) => (
        <SwiperSlide key={index}>{child}</SwiperSlide>
      ))}
    </SwiperScroll>
  );
};

export default SliderWrapper;
