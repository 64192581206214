import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IFeatures, User } from "../../types";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

interface UserState {
  user: User;
  access_token: string;
  refresh_token: string;
  loggedIn: boolean;
  loading: boolean;
  error: string | null;
  isCompanyConfirmed: boolean; // Added
  isSubscriptionValid: boolean | null;
}

const initialState: UserState = {
  user: {
    name: "",
    email: "",
    id: "",
    industry: "",
    confirmed: false,
    created_at: "",
    stripe: {
      customer: "",
      subscription: "",
    },
    features: {
      playlist: false,
      recommendations: false,
      video_resource: false,
    },
    devices: 0,
    free_trial: false,
    free_trial_end_date: null,
    free_trial_banner_dismissed: false,
  },
  access_token: "",
  refresh_token: "",
  loggedIn: false,
  loading: false,
  error: null,
  isCompanyConfirmed: false, // Added
  isSubscriptionValid: null, // Added
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateUser: (state, action: PayloadAction<User>) => {
      state.user = { ...state.user, ...action.payload };
    },
    login: (
      state,
      action: PayloadAction<{ access_token: string; refresh_token: string }>
    ) => {
      state.access_token = action.payload.access_token;
      state.refresh_token = action.payload.refresh_token;
      state.loggedIn = true;
    },
    logout: (state) => {
      state.access_token = "";
      state.refresh_token = "";
      state.loggedIn = false;
      state.user = initialState.user;
      state.isCompanyConfirmed = false; // Reset flags
      state.isSubscriptionValid = false; // Reset flags
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
    setFeatures: (
      state,
      action: PayloadAction<{
        devices?: number;
        features?: IFeatures;
        free_trial?: boolean;
        free_trial_end_date?: string | null;
      }>
    ) => {
      state.user.features = action.payload.features ?? state.user.features;
      state.user.devices = action.payload.devices ?? state.user.devices;
      state.user.free_trial = action.payload.free_trial ?? state.user.free_trial;
      state.user.free_trial_end_date =
        action.payload.free_trial_end_date ?? state.user.free_trial_end_date;
    },
    setFreeTrialBannerDismissed: (state, action: PayloadAction<boolean>) => {
      state.user.free_trial_banner_dismissed = action.payload;
    },
    // Added actions to set confirmation and subscription validity
    setCompanyConfirmed: (state, action: PayloadAction<boolean>) => {
      state.isCompanyConfirmed = action.payload;
    },
    setSubscriptionValid: (state, action: PayloadAction<boolean>) => {
      state.isSubscriptionValid = action.payload;
    },
  },
});

export const {
  updateUser,
  login,
  logout,
  setLoading,
  setError,
  setFeatures,
  setFreeTrialBannerDismissed,
  setCompanyConfirmed,
  setSubscriptionValid,
} = userSlice.actions;

// Define the persist config specifically for the user slice
const userPersistConfig = {
  key: "user",
  storage: storage,
  whitelist: ["access_token", "refresh_token", "loggedIn", "user", "isCompanyConfirmed", "isSubscriptionValid"],
};

const persistedUserReducer = persistReducer(
  userPersistConfig,
  userSlice.reducer
);

export default persistedUserReducer;
