import React, { useState } from "react";
import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Theme,
  CardActions,
  IconButton,
  Box,
  Skeleton,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info"; // Example icon for info type
import WarningIcon from "@mui/icons-material/Warning"; // Example icon for warn type
import ErrorIcon from "@mui/icons-material/Error";
import VisibilityIcon from "@mui/icons-material/Visibility"; // Icon for marking as seen
import DeleteIcon from "@mui/icons-material/Delete"; // Icon for deleting notification
import { makeStyles } from "@mui/styles";
import useBreakpoints from "../../hooks/useBreakPoints";
import { Notification } from "../../types";
import {
  useSeenAllMyNotificationsMutation,
  useDeleteNotificationMutation,
  useInteractWithNotificationMutation,
  useGetAllMyNotificationsQuery,
} from "../../redux/api/notificationApi";
import { useGetVideoByIdQuery } from "../../redux/api/videoApi";
import { useAlert } from "../../contexts/AlertContext";
import LoadingView from "../../components/LoadingView";
import ErrorView from "../../components/ErrorView";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import NotificationSkeleton from "../../components/Skeletons/NotificationSkeleton";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import PaginationControl from "../../components/PaginationControl";

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    marginTop: theme.spacing(5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),

    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(15),
    },
    marginBottom: 2,
    color: "#121212",
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  card: {
    boxShadow: "none",
  },
  cardContent: {
    "&:last-child": {
      paddingBottom: theme.spacing(2), // Ensures padding-bottom is consistent
    },
    paddingTop: theme.spacing(2), // Consistent with the vertical padding
    paddingLeft: 0, // Removes horizontal padding
    paddingRight: 0, // Removes horizontal padding
  },
  dot: {
    display: "inline-block",
    width: theme.spacing(1),
    height: theme.spacing(1),
    borderRadius: "50%",
    marginLeft: theme.spacing(1),
  },
  seenDot: {
    backgroundColor: "gray",
  },
  unseenDot: {
    backgroundColor: "orange",
  },
  content: {
    flexGrow: 1,
  },
}));

interface NotificationCardProps extends Notification {}

// NotificationCard component with type safety
const NotificationCard: React.FC<NotificationCardProps> = ({
  id,
  subject,
  description,
  type,
  seen,
  interact,
  created_at,
  video,
}) => {
  const classes = useStyles();
  const { success, error } = useAlert();
  const { t } = useTranslation();
  const { isXs, isSm, isMd, isLg, isXl } = useBreakpoints();

  // Hooks for mutations
  const [markAsSeen] = useInteractWithNotificationMutation();
  const [deleteNotification] = useDeleteNotificationMutation();

  const { data, isLoading, isError } = useGetVideoByIdQuery(video.id);

  const handleMarkAsSeen = async () => {
    try {
      await markAsSeen(id);
      // handle success response
    } catch (error) {
      // handle error
    }
  };

  const handleDelete = async () => {
    try {
      const response = await deleteNotification(id).unwrap();
      if (response.success) {
        success(t("NotificationsPage.DeleteSuccessMessage"));
      } else {
        error(t("NotificationsPage.DeleteErrorMessage"));
      }
    } catch (err) {
      error(t("NotificationsPage.DeleteErrorMessage"));
    }
  };

  const renderIcon = () => {
    switch (type) {
      case "info":
        return <InfoIcon color="info" fontSize="large" />;
      case "warn":
        return <WarningIcon color="warning" fontSize="large" />;
      case "error":
        return <ErrorIcon color="error" fontSize="large" />;
      default:
        return null;
    }
  };

  const getTypePhrase = () => {
    switch (type) {
      case "info":
        return "Update";
      case "warn":
        return "Warning";
      case "error":
        return "Error";
      default:
        return "";
    }
  };

  const contentHeight = isXl ? 304 : isLg ? 250 : isMd ? 200 : isSm ? 150 : 240;
  const contentWidth = isXl ? 362 : isLg ? 310 : isMd ? 428 : isSm ? 328 : 228;

  return (
    <Card className={classes.card}>
      {data?.data?.thumbnails?.length > 0 ? (
        <CardMedia
          image={data?.data?.thumbnails?.[0]?.url} // Update this path to fetch the correct image using video.id
          title={subject}
          sx={{ height: contentHeight, borderRadius: 10 }}
        />
      ) : (
        <Box
          sx={{
            display: "flex",
            height: contentHeight,
            width: "100%",
            borderRadius: 10,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#EC674C",
          }}
        >
          <NotificationsActiveIcon fontSize="large" sx={{ color: "white" }} />
        </Box>
      )}

      <CardContent className={classes.cardContent}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="subtitle2" color="textSecondary">
              {getTypePhrase()}
            </Typography>
            <Box
              className={`${classes.dot} ${
                seen || interact ? classes.seenDot : classes.unseenDot
              }`}
            />
          </div>
          <CardActions className={classes.actions}>
            {!seen || !interact ? (
              <IconButton onClick={handleMarkAsSeen}>
                <VisibilityIcon />
              </IconButton>
            ) : null}
            <IconButton onClick={handleDelete}>
              <DeleteIcon />
            </IconButton>
          </CardActions>
        </Box>
        <Typography gutterBottom variant="h6" color="textPrimary">
          {subject}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {description}
        </Typography>
        <Typography variant="caption" color="textSecondary">
          {new Date(created_at).toLocaleDateString()}
        </Typography>
      </CardContent>
    </Card>
  );
};

// NotificationsComponent with type safety
const NotificationsPage: React.FC = () => {
  const classes = useStyles();
  // Pagination state
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  // Fetch notifications
  const {
    data: notifications,
    isLoading,
    isError,
    error,
  } = useGetAllMyNotificationsQuery({
    page_number: pageNumber,
    page_size: pageSize,
  });

  const handleChangePage = (newPage: number) => {
    setPageNumber(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNumber(1); // Reset to the first page when rows per page changes
  };

  if (isLoading) {
    return (
      <Box className={classes.container}>
        <Skeleton
          variant="rectangular"
          sx={{ marginBottom: 15, width: "20%" }}
        />
        <Grid container spacing={2}>
          {Array.from(new Array(10)).map((_, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <NotificationSkeleton />
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  }

  if (isError) {
    console.error(error); // Log the error for debugging
    return (
      <Box className={classes.content}>
        <ErrorView
          message={t("NotificationsPage.FailedToLoadNotifications")}
          type="error"
        />
      </Box>
    ); // Error message for the user
  }
  return (
    <div className={classes.container}>
      <h1 style={{ marginBottom: 65 }}>{t("NotificationsPage.PageTitle")}</h1>
      {notifications && notifications.data && notifications.data.length ? (
        <>
          <Grid container spacing={5}>
            {notifications.data.map((notification, index) => (
              <Grid item key={index} xs={12} sm={4} md={3}>
                <NotificationCard {...notification} />
              </Grid>
            ))}
          </Grid>
          <PaginationControl
            stickyBottom
            rowsPerPage={pageSize}
            totalCount={notifications.count}
            pageNo={pageNumber}
            disablePrevBtn={pageNumber === 1}
            disableNextBtn={
              notifications.count !== -1
                ? pageNumber >= Math.ceil(notifications.count / pageSize)
                : false
            }
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleChangePage={handleChangePage}
          />
        </>
      ) : (
        <Box className={classes.content}>
          <ErrorView
            message={t("NotificationsPage.NoNotifications")}
            type="info"
          />
        </Box>
      )}
    </div>
  );
};

export default NotificationsPage;
