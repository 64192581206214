import React, { useState, useRef, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import ReactPlayer from "react-player";
import {
  PlayArrow as PlayArrowIcon,
  Pause as PauseIcon,
  FastForward as FastForwardIcon,
  FastRewind as FastRewindIcon,
  Fullscreen as FullscreenIcon,
  FullscreenExit as FullscreenExitIcon,
  Settings as SettingsIcon,
  VolumeOff,
  VolumeUp,
  SkipNext,
  SkipPrevious,
  ClosedCaption as ClosedCaptionIcon,
} from "@mui/icons-material";
import "./video.css";
import {
  IconButton,
  Popover,
  Slider,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
  LinearProgress,
  Box,
  MenuList,
  MenuItem,
} from "@mui/material";
import BackButton from "../../components/BackButton";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import VolumeSlider from "../../components/VolumeSlider";
import { selectMedia } from "../../utils/getMedia";
import { useContinueWatching } from "../../hooks/useContinueWatching";
import { setCurrentVideo } from "../../redux/state/videoSlice";
import ProgressBar from "./ProgressBar";
import {
  useGetViewByProgramIdAndVideoIdQuery,
  useGetViewWatchAnalyticsQuery,
  useSendVideoViewMutation,
  useUpdateVideoViewMutation,
} from "../../redux/api/analyticsApi";
import { UpdateVideoView } from "../../types";
import {
  calculateThreshold,
  getDeviceType,
  getPlatform,
} from "../../utils/analyticsFunctions";

enum thresholdReason {
  view = "view",
  complete = "complete",
}

const NowPlayingPage: React.FC = () => {
  const { currentVideo, progress, total } = useSelector(
    (state: RootState) => state.video
  );
  const { currentProgram } = useSelector((state: RootState) => state.program);
  const { id } = useSelector((state: RootState) => state.user.user);

  const { personalSubtitle, personalVideoResolution } = useSelector(
    (state: RootState) => state.settings.personalSettings
  );
  const videoRef = useRef<HTMLVideoElement>(null);

  const playerContainerRef = useRef<HTMLDivElement>(null);
  const [playing, setPlaying] = useState<boolean>(false);
  const [played, setPlayed] = useState<number>(0);
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [totalDuration, setTotalDuration] = useState<number>(0);
  const [showContinueDialog, setShowContinueDialog] = useState(false);
  const [savedProgressTime, setSavedProgressTime] = useState(0);
  const [showControls, setShowControls] = useState(false);
  const [loading, setLoading] = useState(false);
  const [continueWatchingId, setContinueWatchingId] = useState("");
  const [videoError, setVideoError] = useState<string | null>(); // Error state
  const hideControlsTimerRef = useRef<NodeJS.Timeout | null>(null);
  const [viewId, setViewId] = useState("");
  const [markedAsViewed, setMarkedAsViewed] = useState(false);
  const [markedAsComplete, setMarkedAsComplete] = useState(false);

  const dispatch = useDispatch();

  const [ccAnchorEl, setCcAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [selectedCaption, setSelectedCaption] = useState<string | null>(null);
  const [captionLang, setCaptionLang] = useState<string | null>(null);

  // Volume Popover State
  const [volumeAnchorEl, setVolumeAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const [volume, setVolume] = useState<number>(1); // 1 is maximum (100%)

  // Settings Popover State
  const [settingsAnchorEl, setSettingsAnchorEl] =
    useState<HTMLButtonElement | null>(null);

  // Open states for popovers
  const isVolumePopoverOpen = Boolean(volumeAnchorEl);
  const isSettingsPopoverOpen = Boolean(settingsAnchorEl);
  const isCcPopoverOpen = Boolean(ccAnchorEl);

  const { fetchVideoContinueWatching, update, remove } = useContinueWatching(
    currentProgram.id
  );

  const [
    createVideoView,
    { data: createVideoViewData, isLoading: isCreatingVideoView },
  ] = useSendVideoViewMutation();

  const [
    updateVideoView,
    { data: updateVideoViewData, isLoading: isUpdatingVideoView },
  ] = useUpdateVideoViewMutation();

  const { data: viewWatchAnalyics } = useGetViewByProgramIdAndVideoIdQuery({
    video_id: currentVideo.id,
    program_id: currentProgram.id,
  });

  // Handler for click event
  const checkProgress = useCallback(async () => {
    setLoading(true);

    try {
      const { data: updatedData } = await fetchVideoContinueWatching({
        program_id: currentProgram.id,
        video_id: currentVideo.id,
        sort: "asc",
        field: "created_at",
        page_number: 1,
        page_size: 1,
      });

      if (updatedData && updatedData.count > 0) {
        dispatch(
          setCurrentVideo({
            video: currentVideo,
            programId: currentProgram.id,
            progress: updatedData.data[0].current_position,
            total: currentVideo.medias[0].length,
          })
        );
        const progressTime = updatedData.data[0].current_position;
        const completed = updatedData.data[0].is_complete;
        setMarkedAsComplete(completed);
        setContinueWatchingId(updatedData.data[0].id);
        setSavedProgressTime(progressTime);
        if (progressTime > 0 && !completed) {
          setShowContinueDialog(true);
        }
      } else {
        dispatch(
          setCurrentVideo({
            video: currentVideo,
            programId: currentProgram.id,
            progress,
            total,
          })
        );
        setSavedProgressTime(progress);
        if (progress > 0) {
          setShowContinueDialog(true);
        }
      }
    } catch (error) {
      console.error("Error fetching video continue watching data:", error);
    } finally {
      setLoading(false);
    }
  }, [fetchVideoContinueWatching, currentProgram.id, currentVideo, dispatch]);

  const markVideoAsViewed = async () => {
    if (videoRef.current) {
      const currentTime = videoRef.current.currentTime;
      const totalLength = videoRef.current.duration;
      try {
        const response = await createVideoView({
          company_id: id,
          video_id: currentVideo.id,
          program_id: currentProgram.id,
          retention_rate: currentTime / totalLength,
          device_type: getDeviceType(), // Add device type
          platform: getPlatform(), // Add platform
        }).unwrap();
        setMarkedAsViewed(true);
        setViewId(response.data.id);
      } catch (error) {
        console.error("Error marking video as viewed:", error);
      }
    }
  };

  const updateVideoAnalytics = async () => {
    if (videoRef.current) {
      const currentTime = videoRef.current.currentTime;
      const totalLength = videoRef.current.duration;
      try {
        const response = await updateVideoView({
          id: viewId,
          retention_rate: currentTime / totalLength,
        }).unwrap();
      } catch (error) {
        console.error("Error updating video view:", error);
      }
    }
  };

  // Function to handle progress bar change
  const handleProgressChange = (newValue: number) => {
    const newTime = (newValue / 100) * totalDuration;
    setCurrentTime(newTime);
    if (videoRef.current) {
      videoRef.current.currentTime = newTime;
    }
  };

  const handleCcClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setCcAnchorEl(event.currentTarget);
  };

  const handleCcClose = () => {
    setCcAnchorEl(null);
  };

  const handleCaptionSelect = (
    captionUrl: string | null,
    language: string | null
  ) => {
    // Ensure that the URL ends with only a single .vtt
    const validCaptionUrl = captionUrl?.replace(/\.vtt\.vtt$/, ".vtt");

    setSelectedCaption(captionUrl);
    setCaptionLang(language);
    handleCcClose();
  };

  const handleMouseDown = () => {
    if (videoRef.current && !videoRef.current.paused) {
      videoRef.current.pause();
      setPlaying(false); // Update the playing state
    }
  };

  const handleMouseUp = () => {
    if (videoRef.current && !playing) {
      videoRef.current.play().catch((error) => {
        console.error("Error attempting to play the video:", error);
        setVideoError("An error occurred while trying to play the video.");
      });
      setPlaying(true); // Resume playing
    }
  };

  // Volume Handlers
  const toggleVolumeSlider = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (volumeAnchorEl) {
      setVolumeAnchorEl(null);
    } else {
      setVolumeAnchorEl(event.currentTarget);
    }
  };

  const handleVolumeClose = () => {
    setVolumeAnchorEl(null);
  };

  // Update to handle video metadata loaded event
  const handleMetadataLoaded = () => {
    if (videoRef.current) {
      setTotalDuration(videoRef.current.duration);
    }
  };

  // Update to handle volume change
  const handleVolumeChange = (event: Event, newValue: number | number[]) => {
    const newVolume = (newValue as number) / 100; // Convert to a fraction
    setVolume(newVolume);
    if (videoRef.current) {
      videoRef.current.volume = newVolume;
    }
  };

  // Settings Handlers
  const handleSettingsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setSettingsAnchorEl(event.currentTarget);
  };

  const handleSettingsClose = () => {
    setSettingsAnchorEl(null);
  };

  const handleRewind = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = videoRef.current.currentTime - 10;
      setCurrentTime(videoRef.current.currentTime - 10);
    }
  };

  const handleFastForward = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = videoRef.current.currentTime + 10;
      setCurrentTime(videoRef.current.currentTime + 10);
    }
  };

  const togglePlay = () => {
    if (videoRef.current) {
      const isPlaying = !videoRef.current.paused;

      if (isPlaying) {
        videoRef.current.pause();
        setPlaying(false);
      } else {
        videoRef.current.play().catch((error) => {
          console.error("Error attempting to play the video:", error);
          setVideoError("An error occurred while trying to play the video.");
        });
        setPlaying(true);
      }
    }
  };

  const handleError = () => {
    if (videoRef.current) {
      const error = videoRef.current.error;
      if (error) {
        let errorMessage = "An unknown error occurred.";
        switch (error.code) {
          case error.MEDIA_ERR_ABORTED:
            errorMessage = "The video playback was aborted.";
            break;
          case error.MEDIA_ERR_NETWORK:
            errorMessage = "A network error caused the video download to fail.";
            break;
          case error.MEDIA_ERR_DECODE:
            errorMessage =
              "The video playback was aborted due to a corruption problem or because the video used features your browser did not support.";
            break;
          case error.MEDIA_ERR_SRC_NOT_SUPPORTED:
            errorMessage =
              "The video could not be loaded, either because the server or network failed or because the format is not supported.";
            break;
        }
        console.error("Video Error: ", errorMessage);
        setVideoError(errorMessage); // Set the error message
      }
    }
  };

  const handleProgress = useCallback(async () => {
    if (videoRef.current) {
      const currentTime = videoRef.current.currentTime;
      const totalLength = videoRef.current.duration;
      setCurrentTime(currentTime);
      setPlayed((currentTime / totalLength) * 100);
      console.log("Saving Progress");
      saveProgress(currentTime, totalLength);

      const viewThreshold = calculateThreshold(
        totalLength,
        thresholdReason.view
      );

      if (currentTime >= viewThreshold && !markedAsViewed && !viewId) {
        markVideoAsViewed(); // Function to send data that the video is viewed
      }

      if (viewId) {
        updateVideoAnalytics();
      }

      if (currentTime === totalLength && continueWatchingId) {
        try {
          await remove(continueWatchingId);
        } catch (error) {
          console.error("Error removing continue watching:", error);
        }
      }
    }
  }, [videoRef.current, viewId, markVideoAsViewed, continueWatchingId]);

  const saveProgress = async (currentTime: number, length: number) => {
    const completeThreshold = calculateThreshold(
      length,
      thresholdReason.complete
    );

    // Only set is_complete if markedAsComplete is false and the user has crossed the completeThreshold
    if (
      videoRef.current &&
      currentProgram?.id &&
      currentVideo?.id &&
      continueWatchingId
    ) {
      const progressData = {
        current_position: currentTime,
        is_complete: markedAsComplete,
      };

      // Update is_complete only if the video is completed and it's not already marked as complete
      if (currentTime >= completeThreshold && !markedAsComplete) {
        progressData["is_complete"] = true;
        setMarkedAsComplete(true);
      }

      try {
        // Update the progress
        await update(continueWatchingId, progressData);
      } catch (error) {
        console.error("Error saving progress:", error);
      }
    }
  };

  // // Handle seeking
  // const handleSeek = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const newPlayed = parseFloat(e.target.value);
  //   if (videoRef.current) {
  //     videoRef.current.currentTime =
  //       (videoRef.current.duration / 100) * newPlayed;
  //   }
  //   setPlayed(newPlayed);
  // };

  // Fullscreen toggle
  const toggleFullscreen = () => {
    const el = playerContainerRef.current;

    if (!document.fullscreenElement && el) {
      el.requestFullscreen();
      setIsFullscreen(true);
    } else if (document.fullscreenElement) {
      document.exitFullscreen();
      setIsFullscreen(false);
    }
  };

  const formatTime = (seconds: number) => {
    const date = new Date(0);
    date.setSeconds(seconds);
    return date.toISOString().slice(11, 19);
  };

  const hideControls = () => {
    console.log("Hiding Controls");
    setShowControls(false);
  };

  const startHideControlsTimer = () => {
    console.log("Starting Hide Controls Timer");
    if (hideControlsTimerRef.current) {
      clearTimeout(hideControlsTimerRef.current);
    }
    hideControlsTimerRef.current = setTimeout(hideControls, 5000); // Hide controls after 5 seconds
  };

  const handleMouseMove = () => {
    console.log("Mouse Move");
    setShowControls(true);
    startHideControlsTimer();
  };

  const handleMouseEnter = () => {
    console.log("Mouse Enter");
    setShowControls(true);
    startHideControlsTimer();
  };

  const handleMouseLeave = () => {
    console.log("Mouse Leave");
    if (hideControlsTimerRef.current) {
      clearTimeout(hideControlsTimerRef.current); // Clear the timer when the mouse leaves
    }
    hideControls();
  };

  const handleContinueFromSavedProgress = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = savedProgressTime;
    }
    setShowContinueDialog(false);
  };

  const handleStartFromBeginning = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
    }
    setSavedProgressTime(0); // Reset the saved progress time
    setShowContinueDialog(false);
  };

  // Function to handle spacebar press
  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.code === "Space") {
      event.preventDefault(); // Prevent the default action of the space key
      togglePlay();
    }
  };

  // Manage Video play/pause interval

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    if (playing) {
      interval = setInterval(() => {
        handleProgress();
      }, 1000);
    } else if (!playing && interval) {
      clearInterval(interval);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [playing, handleProgress]);

  useEffect(() => {
    // Add keydown event listener
    document.addEventListener("keydown", handleKeyPress);

    return () => {
      // Remove event listener on cleanup
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []); // Empty dependency array ensures this effect runs once on mount and cleanup

  useEffect(() => {
    return () => {
      if (hideControlsTimerRef.current) {
        clearTimeout(hideControlsTimerRef.current); // Clear the timer when the component unmounts
      }
    };
  }, []);

  useEffect(() => {
    const handleFullscreenChange = () => {
      const isFullScreen = Boolean(
        document.fullscreenElement === playerContainerRef.current
      );

      setIsFullscreen(isFullScreen);
      if (isFullScreen) {
        document.body.classList.add("no-scrollbar");
      } else {
        document.body.classList.remove("no-scrollbar");
      }
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);

    return () => {
      if (document.fullscreenElement) {
        document.exitFullscreen(); // Exit fullscreen when the component unmounts
      }
      document.body.classList.remove("no-scrollbar"); // Remove the 'no-scrollbar' class
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);

  useEffect(() => {
    checkProgress();
    // Empty dependency array ensures checkProgress only runs on mount
  }, []);

  useEffect(() => {
    if (viewWatchAnalyics && viewWatchAnalyics.data) {
      setViewId(viewWatchAnalyics.data.id);
      setMarkedAsViewed(true);
    }
  }, []);

  return (
    <div
      className={`video-player-container ${
        showControls ? "show-controls" : ""
      }`}
      onMouseEnter={handleMouseEnter}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      ref={playerContainerRef}
    >
      {loading ? (
        <div className="loading-spinner">
          <CircularProgress sx={{ color: "#EC674C" }} />
        </div>
      ) : videoError ? (
        <div className="video-error">
          <Typography variant="h5" sx={{ color: "white" }} textAlign={"center"}>
            {videoError}
          </Typography>
        </div>
      ) : currentVideo && currentVideo.medias.length ? (
        <video
          id="video-player"
          ref={videoRef}
          src={selectMedia(currentVideo.medias)?.url ?? "placeholder_video.mp4"}
          poster={
            currentVideo?.thumbnails?.[0]?.url ?? "placeholder_poster.png"
          }
          // onTimeUpdate={handleProgress}
          onLoadedMetadata={handleMetadataLoaded}
          onEnded={() => setPlaying(false)}
          onError={handleError} // Catch streaming errors
          controls={false}
          width={"100%"}
          height={"100%"}
        >
          {selectedCaption ? (
            <track
              src={selectedCaption}
              kind="subtitles"
              lang={
                captionLang === "english"
                  ? "en"
                  : captionLang === "spanish"
                  ? "es"
                  : "fr"
              }
              label={captionLang.toUpperCase()}
              default={captionLang === "english" ? true : false}
            />
          ) : null}
        </video>
      ) : (
        <div className="video-placeholder">
          <Typography variant="h5">
            Video is unavailable. Please select another video.
          </Typography>
        </div>
      )}

      <div className="controls-overlay">
        <div className="video-title">
          <BackButton />
          <Typography variant="h5" sx={{ color: "white", ml: 5 }}>
            {currentVideo?.title}
          </Typography>
        </div>

        {!videoError && !loading && (
          <div className="control-buttons">
            <IconButton className="playback-button" onClick={handleRewind}>
              <SkipPrevious />
            </IconButton>
            <IconButton
              className="playback-button play-pause-button"
              onClick={togglePlay}
            >
              {playing ? (
                <PauseIcon fontSize="inherit" />
              ) : (
                <PlayArrowIcon fontSize="inherit" />
              )}
            </IconButton>
            <IconButton className="playback-button" onClick={handleFastForward}>
              <SkipNext />
            </IconButton>
          </div>
        )}

        {!videoError && !loading && (
          <div style={{ width: "100%" }}>
            <div className="time-progress-container">
              <div className="time-display">
                <Typography sx={{ color: "white" }}>
                  {formatTime(currentTime)}
                </Typography>
              </div>

              <div className="time-display">
                <Typography sx={{ color: "white" }}>
                  {formatTime(totalDuration)}
                </Typography>
              </div>
            </div>
            <div className="control-bar">
              <Box className="progress-bar">
                <ProgressBar
                  currentTime={currentTime}
                  duration={totalDuration}
                  onProgressChange={handleProgressChange}
                  onMouseDown={handleMouseDown}
                  onMouseUp={handleMouseUp}
                />
              </Box>

              <div className="control-buttons">
                <IconButton
                  className="control-button"
                  onClick={toggleVolumeSlider}
                >
                  <VolumeUp />
                </IconButton>
                <Popover
                  open={isVolumePopoverOpen}
                  anchorEl={volumeAnchorEl}
                  onClose={handleVolumeClose}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  sx={{
                    "&: MuiPopover-paper": {
                      backgroundColor: "transparent",
                    },
                  }}
                >
                  <VolumeSlider
                    volume={volume}
                    onVolumeChange={handleVolumeChange}
                  />
                </Popover>
                {/* <IconButton
                className="control-button"
                onClick={handleSettingsClick}
              >
                <SettingsIcon />
              </IconButton>
              <Popover
                open={isSettingsPopoverOpen}
                anchorEl={settingsAnchorEl}
                onClose={handleSettingsClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              ></Popover> */}
                {currentVideo?.captions?.length > 0 && (
                  <>
                    <IconButton
                      className="control-button"
                      onClick={handleCcClick}
                    >
                      <ClosedCaptionIcon />
                    </IconButton>
                    <Popover
                      open={isCcPopoverOpen}
                      anchorEl={ccAnchorEl}
                      onClose={handleCcClose}
                      anchorOrigin={{ vertical: "top", horizontal: "left" }}
                      transformOrigin={{ vertical: "top", horizontal: "left" }}
                    >
                      <MenuList>
                        {currentVideo.captions.map((caption, index) => (
                          <MenuItem
                            key={index}
                            onClick={() =>
                              handleCaptionSelect(caption.url, caption.language)
                            }
                          >
                            {caption.language}
                          </MenuItem>
                        ))}
                        <MenuItem
                          onClick={() => handleCaptionSelect(null, null)}
                        >
                          Turn off captions
                        </MenuItem>
                      </MenuList>
                    </Popover>
                  </>
                )}
                <IconButton
                  className="control-button"
                  onClick={toggleFullscreen}
                >
                  {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
                </IconButton>
              </div>
            </div>
          </div>
        )}
      </div>
      <Dialog
        open={showContinueDialog}
        onClose={() => setShowContinueDialog(false)}
        aria-labelledby="continue-dialog-title"
        aria-describedby="continue-dialog-description"
      >
        <DialogTitle id="continue-dialog-title">
          {"Continue Watching?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="continue-dialog-description">
            Do you want to continue watching from where you left off or start
            from the beginning?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleStartFromBeginning} color="primary">
            Start from Beginning
          </Button>
          <Button
            onClick={handleContinueFromSavedProgress}
            color="primary"
            autoFocus
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default NowPlayingPage;
