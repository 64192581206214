import React, { useState } from "react";
import {
  List,
  ListItemButton,
  Typography,
  Container,
  Box,
  useTheme,
  Theme,
  Drawer,
  useMediaQuery,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { makeStyles } from "@mui/styles";
import { StyledListItemButton } from "../../components/ListButton";
import LanguageSettings from "./components/LanguageSettings";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import {
  Subtitle,
  setAppSubtitle,
  setAppVideoResolution,
  setLanguage,
  setPersonalSubtitle,
  setPersonalVideoResolution,
} from "../../redux/state/settingsSlice";
import SubtitleSettings from "./components/SubtitlesSettings";
import VideoResolutionSettings from "./components/ResolutionSettings";
import AccountSettings from "./components/AccountSettings";
import { useTranslation } from "react-i18next";
import SubscriptionSettings from "./components/SubscriptionSettings";
import { useGetSubscriptionQuery } from "../../redux/api/subscriptionApi";

interface ItemProps {
  primary: string;
  secondary: string;
}

// Custom ListItem component for horizontal text layout
export const ListItemCustom = ({ primary, secondary }: ItemProps) => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
    }}
  >
    <Typography variant="body1">{primary}</Typography>
    <Typography variant="body2" color="text.secondary">
      {secondary}
    </Typography>
  </Box>
);

const useStyles = makeStyles<Theme>((theme) => ({
  mainContainer: {
    marginTop: theme.spacing(5),
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(15), // Adjust based on the drawer width
      width: "40%",
    },
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
  },
  contentContainer: {
    flexGrow: 1,
    padding: theme.spacing(5),
  },
  drawerPaper: {
    padding: theme.spacing(4),
    [theme.breakpoints.up("xs")]: {
      width: "80%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "60%",
    },
    [theme.breakpoints.up("md")]: {
      width: "30%", // Adjust width as needed
      borderLeft: `1px solid ${theme.palette.divider}`,
    },
    // [theme.breakpoints.up("lg")]: {
    //   paddingLeft: theme.spacing(12),
    //   paddingTop: theme.spacing(12),
    // },
  },
}));

const SettingsPage = () => {
  const [selectedIndex, setSelectedIndex] = useState<number>(1);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const classes = useStyles();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const dispatch = useDispatch();
  const { appLanguage, appSubtitle, appVideoResolution } = useSelector(
    (state: RootState) => state.settings.appSettings
  );
  const { personalSubtitle, personalVideoResolution } = useSelector(
    (state: RootState) => state.settings.personalSettings
  );
  const { currentProgram } = useSelector((state: RootState) => state.program);

  const subscriptionId = useSelector(
    (state: RootState) => state.user.user.stripe?.subscription
  );

  const { i18n, t } = useTranslation();

  // Fetch subscription details if subscriptionId is defined
  const { data: subscription, isLoading } = useGetSubscriptionQuery(
    subscriptionId || ""
  );

  const status = subscription?.data.status;
  const userSubscription = t(
    `Settings.Subscriptions.${
      status === "active"
        ? "Subscribed"
        : status === "cancel"
        ? "NotSubscribed"
        : status === "pause"
        ? "Paused"
        : "Pending"
    }`
  );

  const appSubtitleStatus = appSubtitle.enabled
    ? t("common:On")
    : t("common:Off");
  const personalSubtitleStatus = personalSubtitle.enabled
    ? t("common:On")
    : t("common:Off");

  const languages: { code: "en" | "fr" | "es"; name: string }[] = [
    { code: "en", name: t("Settings.Languages.en") },
    { code: "fr", name: t("Settings.Languages.fr") },
    { code: "es", name: t("Settings.Languages.es") },
  ];

  const language = languages.filter((lang) => {
    return appLanguage === lang.code;
  });

  const handleLanguageSelect = (language: "en" | "fr" | "es") => {
    dispatch(setLanguage(language)); // Replace `setLanguage` with your actual action
    i18n.changeLanguage(language);
    handleDrawerClose();
  };

  const handleAppResolution = (resolution: string) => {
    dispatch(setAppVideoResolution(resolution));
  };

  const handleAppSubtitleChange = (subtitle: Subtitle) => {
    dispatch(setAppSubtitle(subtitle));
  };

  const handlePersonalResolution = (resolution: string) => {
    dispatch(setPersonalVideoResolution(resolution));
  };

  const handlePersonalSubtitleChange = (subtitle: Subtitle) => {
    dispatch(setPersonalSubtitle(subtitle));
  };

  const handleListItemClick = (index: number) => {
    setSelectedIndex(index);
    if (!isMdUp) {
      setDrawerOpen(true);
    }
  };

  const handleDrawerClose = () => {
    if (!isMdUp) {
      setDrawerOpen(false);
    }
  };

  const renderDrawerContent = () => {
    switch (selectedIndex) {
      case 1:
        return (
          <LanguageSettings
            selectedLanguage={appLanguage}
            onLanguageSelect={handleLanguageSelect}
          />
        );
      case 2:
        return (
          <SubtitleSettings
            onSubtitleChange={handleAppSubtitleChange}
            subtitle={appSubtitle}
          />
        );
      case 3:
        return (
          <VideoResolutionSettings
            onResolutionSelect={handleAppResolution}
            selectedResolution={appVideoResolution}
          />
        );
      case 4:
        return <AccountSettings />;
      case 5:
        return (
          <SubtitleSettings
            onSubtitleChange={handlePersonalSubtitleChange}
            subtitle={personalSubtitle}
          />
        );
      case 6:
        return (
          <VideoResolutionSettings
            onResolutionSelect={handlePersonalResolution}
            selectedResolution={personalVideoResolution}
          />
        );
      case 7:
        return <SubscriptionSettings />;
    }
  };

  return (
    <Box className={classes.mainContainer}>
      <Container maxWidth="sm" className={classes.contentContainer}>
        <h1 style={{ marginBottom: 5 }}>
          {t("Settings.Title")}
        </h1>

        <Typography variant="subtitle1" sx={{ my: 2 }}>
          {t("Settings.AppSettings")}
        </Typography>

        <List component="nav" aria-label="main settings">
          <StyledListItemButton
            selected={selectedIndex === 1}
            onClick={() => handleListItemClick(1)}
          >
            <ListItemCustom
              primary={t("Settings.LanguageSetting.Title")}
              secondary={language[0].name}
            />
            <ArrowForwardIosIcon />
          </StyledListItemButton>

          {/* <StyledListItemButton
            selected={selectedIndex === 2}
            onClick={() => handleListItemClick(2)}
          >
            <ListItemCustom
              primary={t("Settings.SubtitleSetting.Title")}
              secondary={appSubtitleStatus}
            />
            <ArrowForwardIosIcon />
          </StyledListItemButton> */}

          {/* <StyledListItemButton
            selected={selectedIndex === 3}
            onClick={() => handleListItemClick(3)}
          >
            <ListItemCustom
              primary={t("Settings.VideoResolutionSetting.Title")}
              secondary={appVideoResolution}
            />
            <ArrowForwardIosIcon />
          </StyledListItemButton> */}
        </List>

        <Typography variant="subtitle1" sx={{ my: 2 }}>
          {t("Settings.PersonalSettings")}
        </Typography>

        <List component="nav" aria-label="personal settings">
          <StyledListItemButton
            selected={selectedIndex === 4}
            onClick={() => handleListItemClick(4)}
          >
            <ListItemCustom
              primary={t("Settings.AccountSettings.Title")}
              secondary={currentProgram?.nickname!}
            />
            <ArrowForwardIosIcon />
          </StyledListItemButton>

          {/* <StyledListItemButton
            selected={selectedIndex === 5}
            onClick={() => handleListItemClick(5)}
          >
            <ListItemCustom
              primary={t("Settings.SubtitleSetting.Title")}
              secondary={personalSubtitleStatus}
            />
            <ArrowForwardIosIcon />
          </StyledListItemButton> */}

          {/* <StyledListItemButton
            selected={selectedIndex === 6}
            onClick={() => handleListItemClick(6)}
          >
            <ListItemCustom
              primary={t("Settings.VideoResolutionSetting.Title")}
              secondary={personalVideoResolution}
            />
            <ArrowForwardIosIcon />
          </StyledListItemButton> */}

          <StyledListItemButton
            selected={selectedIndex === 7}
            onClick={() => handleListItemClick(7)}
          >
            <ListItemCustom
              primary={t("Settings.Subscriptions.Title")}
              secondary={userSubscription}
            />
            <ArrowForwardIosIcon />
          </StyledListItemButton>
        </List>
      </Container>
      <Drawer
        variant={isMdUp ? "permanent" : "temporary"}
        anchor="right"
        open={isMdUp || drawerOpen}
        onClose={handleDrawerClose}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        {renderDrawerContent()}
      </Drawer>
    </Box>
  );
};

export default SettingsPage;
