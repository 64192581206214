// SubscriptionPlanCard.tsx

import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Chip,
  useTheme,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  CircularProgress,
  Tooltip,
  Alert,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { SubscriptionPlan, IPrice, CreateSessionPayload } from "../../../../types";
import { useCreatePaymentSessionMutation } from "../../../../redux/api/subscriptionApi";
import { useAlert } from "../../../../contexts/AlertContext";

const getGradient = (name: string): string => {
  const gradients: { [key: string]: string } = {
    Basic: `linear-gradient(135deg, #EC674C 0%, #000000 100%)`,
    Medium: `linear-gradient(135deg, #000000 0%, #EC674C 100%)`,
    Premium: `linear-gradient(135deg, #EC674C 0%, #000000 100%)`,
  };
  return gradients[name as keyof typeof gradients] || gradients["Basic"]; // Fallback to 'Basic' if name is not a key
};

interface SubscriptionPlanCardProps {
  plan: SubscriptionPlan;
}

const SubscriptionPlanCard: React.FC<SubscriptionPlanCardProps> = ({
  plan,
}) => {
  const theme = useTheme();
  const { error } = useAlert()
  const [createPaymentSession, { isLoading }] =
    useCreatePaymentSessionMutation();
  const [selectedPriceId, setSelectedPriceId] = useState<string>(
    plan.prices.length > 0 ? plan.prices[0].stripe.price_id : ""
  );
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleSelectPlan = async () => {
    if (!selectedPriceId) {
      // Handle the case where no price is selected
      console.error("No price selected.");
      setErrorMessage("Please select a pricing option before proceeding.");
      return;
    }

    const selectedPrice: IPrice | undefined = plan.prices.find(
      (price) => price.stripe.price_id === selectedPriceId
    );

    if (!selectedPrice) {
      console.error("Selected price not found.");
      setErrorMessage("Selected pricing option is invalid.");
      return;
    }

    const paymentPayload: CreateSessionPayload  = {
      price_id: selectedPrice.stripe.price_id,
      // Add any other necessary fields here
    };

    if (selectedPrice.trial_days) {
      paymentPayload.free_trail_days = selectedPrice.trial_days
    }

    try {
      const response = await createPaymentSession(paymentPayload).unwrap();
      // Redirect to the checkout URL
      window.location.href = response.element.checkout_url;
    } catch (err) {
      error("Error creating payment session");
      setErrorMessage("Failed to initiate payment. Please try again.");
    }
  };

  const handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedPriceId(event.target.value);
    // Clear any previous error messages
    if (errorMessage) {
      setErrorMessage("");
    }
  };

  return (
    <Card
      sx={{
        borderRadius: "16px",
        boxShadow: theme.shadows[5],
        width: "100%", // Ensures the card takes full width of Grid item
        display: "flex", // Enables Flexbox on Card
        flexDirection: "column",
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          justifyContent: "start",
          flexGrow: 1, // Allows CardContent to grow and fill space
          padding: theme.spacing(3),
          // backgroundImage: getGradient(plan.name),
          color: "#000",
          position: "relative",
        }}
      >
        {/* Plan Name Chip */}
        <Chip
          label={plan.name}
          sx={{
            backgroundColor: theme.palette.primary.main,
            color: "white",
            fontSize: "0.875rem",
            // position: "absolute",
            // top: theme.spacing(2),
            // right: theme.spacing(2),
          }}
        />

        {/* Plan Description */}
        <Typography
          variant="body2"
          gutterBottom
          sx={{ marginY: theme.spacing(4), textAlign: "left" }}
        >
          {plan.description}
        </Typography>

        <Typography gutterBottom variant="body1">
          This plan includes:
        </Typography>

        {/* Features List */}
        <List sx={{ width: "100%" }}>
          {Object.entries(plan.features).map(([feature, included]) => (
            <ListItem key={feature} sx={{ padding: "2px 0" }}>
              <ListItemIcon>
                {included ? (
                  <CheckIcon style={{ color: "green" }} />
                ) : (
                  <CloseIcon style={{ color: "red" }} />
                )}
              </ListItemIcon>
              <ListItemText
                primary={
                  <Tooltip
                    title={`This plan ${
                      included ? "includes" : "does not include"
                    } ${feature.replace(/_/g, " ")}`}
                  >
                    <span style={{ cursor: "pointer" }}>
                      {`${included ? "Access to" : "No access to"} ${feature.replace(/_/g, " ")}`}
                    </span>
                  </Tooltip>
                }
              />
            </ListItem>
          ))}
          <ListItem sx={{ padding: "2px 0" }}>
            <ListItemIcon>
              <CheckIcon style={{ color: "green" }} />
            </ListItemIcon>
            <ListItemText
              primary={
                <Tooltip
                  title={`This plan allows you to be logged in on a maximum of ${plan.devices} devices`}
                >
                  <span style={{ cursor: "pointer" }}>
                    {`Up to ${plan.devices} devices`}
                  </span>
                </Tooltip>
              }
            />
          </ListItem>
        </List>

        {/* Price Selection */}
        <Box sx={{ width: "100%", marginTop: theme.spacing(2), flexGrow: 1 }}>
          <Typography variant="h6" sx={{ marginBottom: theme.spacing(1) }}>
            Select Pricing Option
          </Typography>
          <RadioGroup
            aria-label="pricing-options"
            name="pricing-options"
            value={selectedPriceId}
            onChange={handlePriceChange}
          >
            {plan.prices
              .filter((price) => !price.deleted)
              .map((price) => (
                <FormControlLabel
                  key={price.stripe.price_id}
                  value={price.stripe.price_id}
                  control={<Radio />}
                  label={`$${(price.amount / 100).toFixed(2)} / ${
                    price.interval
                  } ${price.trial_days ? `(Trial: ${price.trial_days} days)` : ""}`}
                  sx={{
                    backgroundColor:
                      selectedPriceId === price.stripe.price_id
                        ? "rgba(238, 103, 76, 0.2)"
                        : "transparent",
                    borderRadius: "8px",
                    padding: "8px",
                    marginBottom: "4px",
                    "&:hover": {
                      backgroundColor: "rgba(238, 103, 76, 0.1)",
                    },
                  }}
                />
              ))}
          </RadioGroup>
        </Box>

        {/* Error Message */}
        {errorMessage && (
          <Alert severity="error" sx={{ width: "100%", mt: 2 }}>
            {errorMessage}
          </Alert>
        )}

        {/* Spacer Box to Push Button to Bottom */}
        <Box sx={{ flexGrow: 1 }} />

        {/* Select Plan Button */}
        <Button
          onClick={handleSelectPlan}
          variant="contained"
          fullWidth
          sx={{
            backgroundColor: theme.palette.primary.main,
            borderRadius: "10px",
            padding: "10px 30px",
            mx: 0,
            color: "#fff",
            textTransform: "none",
            marginTop: theme.spacing(3),
            "&:hover": {
              backgroundColor: theme.palette.primary.dark,
            },
          }}
          disabled={isLoading || !selectedPriceId}
        >
          {isLoading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            "Select Plan"
          )}
        </Button>
      </CardContent>
    </Card>
  );
};

export default SubscriptionPlanCard;
