import { SwiperSlide } from "swiper/react";
import SwiperScroll from "../../../../components/SwiperScroll";
import useBreakpoints from "../../../../hooks/useBreakPoints";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import Thumbnail from "../../../../components/Thumbnail";
import { Video } from "../../../../types";
import LoadingView from "../../../../components/LoadingView";
import ErrorView from "../../../../components/ErrorView";
import SliderWrapper from "../../../../components/SliderWrapper";

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    marginTop: theme.spacing(10),
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(15),
    },
  },
}));

interface SearchResultProps {
  videos: Video[];
  loading: boolean;
  isError: boolean;
}

const SearchResults = ({ videos, loading, isError }: SearchResultProps) => {
  const classes = useStyles();
  const { isXs, isSm, isMd, isLg, isXl } = useBreakpoints();

  const slidesPerView = isXs
    ? 1.5
    : isSm
    ? 3
    : isMd
    ? 3
    : isLg
    ? 4.2
    : isXl
    ? 4.5
    : 1;

    if (loading) {
      return <LoadingView />
    }

    if (isError) {
      return <ErrorView message="Could not load results" type="error"/>
    }

  return (
    <div className={classes.container}>
      <h2 style={{ marginBottom: 2, color: "#121212" }}>
        Search Results
      </h2>
      <SliderWrapper slidesPerView={slidesPerView}>
        {videos.map((video) => (
          <Thumbnail key={video._id} video={video} videoId={video._id} />
        ))}
      </SliderWrapper>
    </div>
  );
};

export default SearchResults;
