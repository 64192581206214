// videoSlice.js
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Video } from '../../types';

interface VideoState {
  currentVideo: Video | null;
  programId?: string;
  progress?: number;
  total?: number;
}

const initialState: VideoState = {
  currentVideo: null,
};

interface SetCurrentVideoPayload {
  video: Video | null;
  programId?: string;
  progress?: number;
  total?: number;
}

const videoSlice = createSlice({
  name: 'video',
  initialState,
  reducers: {
    setCurrentVideo: (state, action: PayloadAction<SetCurrentVideoPayload>) => {
      const { video, programId, progress, total } = action.payload;
      state.currentVideo = video;
      if (programId !== undefined) state.programId = programId;
      if (progress !== undefined) state.progress = progress;
      if (total !== undefined) state.total = total;
    },
    clearCurrentVideo: (state) => {
      state.currentVideo = null;
      state.programId = undefined;
      state.progress = undefined;
      state.total = undefined;
    },
  },
});

export const { setCurrentVideo, clearCurrentVideo } = videoSlice.actions;

export default videoSlice.reducer;
