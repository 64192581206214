// Define an interface for the configuration
interface Config {
    apiUrl: string;
}

// Create the configuration object with an initial apiUrl
const config: Config = {
    apiUrl: process.env.REACT_APP_API_URL_DEV || '', // Default to development URL
};

// Update apiUrl based on the environment
if (process.env.NODE_ENV === 'production') {
    config.apiUrl = process.env.REACT_APP_API_URL_PROD || config.apiUrl;
}

// Export the configuration
export default config;
