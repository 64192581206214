// components/Skeletons/HeroSkeleton.jsx
import React from 'react';
import { Box, Skeleton, useTheme, useMediaQuery, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CategoryListSkeleton from './CategoryListSkeleton';

const useStyles = makeStyles((theme: Theme) => ({
  heroContainer: {
    position: 'relative',
    // backdropFilter: 'blur(0px)',
    // WebkitBackdropFilter: 'blur(0px)',
    // "&::before": {
    //   content: '""',
    //   position: "absolute",
    //   top: 0,
    //   right: 0,
    //   bottom: 0,
    //   left: 0,
    //   backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #FFFFFF 100%)`,
    //   backgroundSize: "cover",
    //   backgroundPosition: "center",
    //   zIndex: -1,
    // },
    [theme.breakpoints.up("xs")]: {
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(6),
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(15),
      paddingTop: theme.spacing(15),
    },
  },
  heading: {
    textAlign: "left",
    fontSize: "3rem",
    marginBottom: theme.spacing(2),
    width: "60%",
    [theme.breakpoints.down("md")]: {
      width: "80%",
    },
  },
  chipContainer: {
    display: "flex",
    gap: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  description: {
    textAlign: "left",
    fontSize: "1.4rem",
    marginBottom: theme.spacing(2),
    width: "60%",
    [theme.breakpoints.down("md")]: {
      width: "80%",
    },
  },
  buttonContainer: {
    display: "flex",
    gap: theme.spacing(2),
  },
  childContainer: {
    overflowX: "hidden",
    width: `calc(100% - ${theme.spacing(2)})`,
    [theme.breakpoints.up("xs")]: {
      left: theme.spacing(2),
      width: `calc(100% - ${theme.spacing(2)})`,
    },
    [theme.breakpoints.up("md")]: {
      left: theme.spacing(15),
      width: `calc(100% - ${theme.spacing(15)})`,
    },
    marginTop: theme.spacing(5),
  },
}));

const CategoryHeroSkeleton = () => {
  const theme = useTheme();
  const classes = useStyles();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box
      className={classes.heroContainer}
      sx={{
        // backgroundColor: theme.palette.grey[200],
        borderRadius: theme.shape.borderRadius,
        overflow: 'hidden',
        marginBottom: theme.spacing(2),
        height: isMdUp ? 500 : 300,
      }}
    >
      <Skeleton variant="rectangular" width="100%" height="100%" />
      <Skeleton
        variant="text"
        width="60%"
        height={40}
        className={classes.heading}
      />
      <Box className={classes.chipContainer}>
        <Skeleton variant="rectangular" width={80} height={30} />
        <Skeleton variant="rectangular" width={80} height={30} />
        <Skeleton variant="rectangular" width={80} height={30} />
      </Box>
      <Skeleton
        variant="text"
        width="80%"
        height={20}
        className={classes.description}
      />
      <Skeleton
        variant="text"
        width="60%"
        height={20}
        className={classes.description}
      />
      <Box className={classes.buttonContainer}>
        <Skeleton variant="rectangular" width={140} height={50} />
        <Skeleton variant="rectangular" width={140} height={50} />
      </Box>
      <CategoryListSkeleton isChild={true}/>
    </Box>
  );
};

export default CategoryHeroSkeleton;
