import React, { useState } from "react";
import { SwiperSlide } from "swiper/react";
import SwiperScroll from "../../../../components/SwiperScroll";
import useBreakpoints from "../../../../hooks/useBreakPoints";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import {
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  Theme,
  Tooltip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Thumbnail from "../../../../components/Thumbnail";
import { Playlist as PlaylistType, Video } from "../../../../types";
import {
  useDeletePlaylistMutation,
  useUpdatePlaylistMutation,
} from "../../../../redux/api/playlistApi"; // Adjust import path
import { useAlert } from "../../../../contexts/AlertContext";
import CreatePlaylistDialog from "../../../../components/CreatePlaylistModal";
import { useTranslation } from "react-i18next";
import SliderWrapper from "../../../../components/SliderWrapper";
import ConfirmationDialog from "../../../../components/ConfirmationDialog";

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    marginTop: theme.spacing(10),
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(15),
    },
  },
  playlistTitle: {
    display: "flex",
    alignItems: "center",
    fontWeight: "normal",
  },
}));

interface PlaylistProps {
  playlist: PlaylistType;
}

const Playlist = ({ playlist }: PlaylistProps) => {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isRemoveDialogOpen, setIsRemoveDialogOpen] = useState(false);
  const [videoToRemove, setVideoToRemove] = useState("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null); // For popover

  const classes = useStyles();
  const { isXs, isSm, isMd, isLg, isXl } = useBreakpoints();
  const { name, videos } = playlist;
  const { success, error } = useAlert();
  const { t } = useTranslation();

  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

  const [deletePlaylist] = useDeletePlaylistMutation();
  const [updatePlaylist, { isLoading: isUpdating }] =
    useUpdatePlaylistMutation();

  const handleEdit = () => {
    setIsEditDialogOpen(true);
  };

  const handleCloseEditDialog = () => {
    setIsEditDialogOpen(false);
  };

  const openRemoveDialog = (videoId: string) => {
    setVideoToRemove(videoId);
    setIsRemoveDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await deletePlaylist(playlist.id).unwrap();
      success(t("PlaylistPage.DeleteSuccess"));
      setIsDeleteDialogOpen(false); // Close dialog before performing action
    } catch (err) {
      error(t("PlaylistPage.DeleteError"));
    }
  };

  const handleRemoveConfirm = async () => {
    try {
      const filteredList = playlist.videos.filter(
        (video) => video.id !== videoToRemove
      );

      const updatedList = filteredList.map((video) => ({ id: video.id }));
      await updatePlaylist({
        playlistId: playlist.id,
        videos: updatedList,
      }).unwrap();
      setIsRemoveDialogOpen(false);
      setVideoToRemove("");
      success("Video removed successfully.");
    } catch (err) {
      error("Failed to remove video.");
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const slidesPerView = isXs
    ? 1.5
    : isSm
    ? 3
    : isMd
    ? 3
    : isLg
    ? 4.2
    : isXl
    ? 4.5
    : 1;

  return (
    <div className={classes.container}>
      <div className={classes.playlistTitle}>
        <h3 style={{ color: "#121212", fontWeight: "normal" }}>{name}</h3>
        <IconButton onClick={handleClick}>
          <MoreVertIcon sx={{ color: "#121212" }} />
        </IconButton>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClosePopover}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <List>
            <ListItem button onClick={handleEdit}>
              <ListItemIcon>
                <EditIcon sx={{}}/>
              </ListItemIcon>
              <ListItemText primary={t("PlaylistPage.EditPlaylistButton")} />
            </ListItem>
            <ListItem button onClick={() => setIsDeleteDialogOpen(true)}>
              <ListItemIcon>
                <DeleteIcon />
              </ListItemIcon>
              <ListItemText primary={t("PlaylistPage.DeletePlaylistButton")} />
            </ListItem>
          </List>
        </Popover>
      </div>
      <SliderWrapper slidesPerView={slidesPerView}>
        {videos.map((video) => (
          <Thumbnail
            key={video.id}
            videoId={video.id}
            onDelete={() => openRemoveDialog(video.id)}
          />
        ))}
      </SliderWrapper>

      {/* Edit Playlist Dialog */}
      <CreatePlaylistDialog
        open={isEditDialogOpen}
        onClose={handleCloseEditDialog}
        editingMode={true}
        initialData={{
          id: playlist.id,
          name: playlist.name,
          description: playlist.description,
        }}
      />

      {/** Deletion Confirmation Dialog */}
      <ConfirmationDialog
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        onConfirm={handleDeleteConfirm}
        title={t("confirmationDialog.deletePlaylistTitle")}
        content={t("confirmationDialog.deletePlaylistContent", {
          playlistName: playlist.name,
        })}
      />

      {/** Remove Video Confirmation Dialog */}
      <ConfirmationDialog
        open={isRemoveDialogOpen}
        onClose={() => setIsRemoveDialogOpen(false)}
        onConfirm={handleRemoveConfirm}
        title={t("confirmationDialog.removeVideoTitle")}
        content={t("confirmationDialog.removeVideoContent")}
      />
    </div>
  );
};

export default Playlist;
