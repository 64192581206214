import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import SearchResults from "./components/SearchResults";
import useBreakpoints from "../../hooks/useBreakPoints";
import { useTheme } from "@mui/system";
import {
  useSearchVideosQuery,
  useGetRecommendedVideosQuery,
  useGetAllVideosQuery,
} from "../../redux/api/videoApi"; // Import your search query hook
import { Video } from "../../types";
import Suggestions from "./components/Suggestions";
import StyledTextField from "../../components/Input";
import { useTranslation } from "react-i18next";
import CategoryListSkeleton from "../../components/Skeletons/CategoryListSkeleton";

const SearchPage = (): React.ReactElement => {
  const theme = useTheme();
  const [searchQuery, setSearchQuery] = useState("");
  const [displayResults, setDisplayResults] = useState<Video[]>([]);
  const { isXs, isSm, isMd } = useBreakpoints();
  const searchBarWidth = isXs || isSm ? "95%" : isMd ? "60%" : "50%";
  const { t } = useTranslation();

  const {
    data: searchResults,
    isLoading: loadingSearchResults,
    isError: searchError,
    refetch: refetchSearchResults,
  } = useSearchVideosQuery(
    {
      page_size: 10,
      page_number: 1,
      sort: "asc",
      field: "title",
      q: searchQuery,
      published: true,
    },
    {
      skip: searchQuery === "", // Skip the query if searchQuery is empty
    }
  );

  const {
    data: recommendedVideos,
    isLoading: loadingRecommendedVideos,
    isError,
  } = useGetRecommendedVideosQuery({
    page_size: 10,
    page_number: 1,
    sort: "asc",
    field: "title", // Adjust the field as needed
    published: true,
  });

  const {
    data: allVideos,
    isLoading: iaFetchingAllVideos,
    isError: isAllVideosError,
  } = useGetAllVideosQuery({
    page_size: 10,
    page_number: 1,
    sort: "asc",
    field: "created_at",
    published: true,
  });

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleSearchSubmit = () => {
    if (searchQuery && searchResults?.data) {
      setDisplayResults(searchResults.data); // Update displayed results
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      handleSearchSubmit();
    }
  };

  useEffect(() => {
    if (searchQuery === "") {
      setDisplayResults([]); // Clear results when search query is cleared
    }
  }, [searchQuery]);

  return (
    <div>
      <Box
        display="flex"
        justifyContent="center"
        mt={isXs || isSm ? theme.spacing(8) : theme.spacing(4)}
        width={"100%"}
      >
        <StyledTextField
          placeholder={t("SearchPage.SearchBarPlaceholder")}
          label={t("SearchPage.SearchBarLabel")}
          value={searchQuery}
          onChange={handleSearchChange}
          onKeyDown={handleKeyDown}
          sx={{ width: searchBarWidth }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {loadingSearchResults ? (
                  <CircularProgress />
                ) : (
                  <IconButton onClick={handleSearchSubmit} edge="end">
                    <SearchIcon />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
        />
      </Box>

      {displayResults && displayResults.length > 0 ? (
        <SearchResults
          videos={displayResults}
          loading={loadingSearchResults}
          isError={searchError}
        />
      ) : (
        ""
      )}

      {loadingRecommendedVideos || iaFetchingAllVideos ? (
        <CategoryListSkeleton isChild={false} />
      ) : (
        <Suggestions videos={recommendedVideos?.count ? recommendedVideos.data : allVideos?.data} />
      )}
    </div>
  );
};

export default SearchPage;
