import { SwiperSlide } from "swiper/react";
import SwiperScroll from "../../../../components/SwiperScroll";
import useBreakpoints from "../../../../hooks/useBreakPoints";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import RecommendationsCard from "./RecommendationsCard";
import { Video } from "../../../../types";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(15),
    },
  },
}));

type RecommendationsCard = {
  id: string;
  filmName: string;
  category: string;
  image: string;
  description: string;
  filmLength: string;
};

interface RecommendationsProps {
  videos: Video[];
}

const Recommendations = ({ videos }: RecommendationsProps) => {
  const classes = useStyles();
  const { isXs, isSm, isMd, isLg, isXl } = useBreakpoints();
  const { t } = useTranslation();

  const slidesPerView = isXs
    ? 1
    : isSm
    ? 1.8
    : isMd
    ? 1.8
    : isLg
    ? 2
    : isXl
    ? 2.3
    : 1;

  return (
    <div className={classes.container}>
      <h2 style={{ marginBottom: 2, color: "#121212", fontWeight: "normal" }}>
        {t("HomePage.RecommendationsTitle")}
      </h2>
      <SwiperScroll slidesPerView={slidesPerView}>
        {videos.map((video) => (
          <SwiperSlide key={video._id}>
            <RecommendationsCard
              video={video}
            />
          </SwiperSlide>
        ))}
      </SwiperScroll>
    </div>
  );
};

export default Recommendations;
