export class AppRoutes {
  static readonly AuthBase = "auth";
  static readonly ProgramBase = "programs";
  static readonly RouteBase = "browse";
  static readonly SubscriptionBase = "subscription";

  static readonly Browse = {
    Home: `home`,
    Search: `search`,
    Playlists: `playlists`,
    LiveEvents: `live-events`,
    Categories: `categories`,
    Notifications: `notifications`,
    Settings: `settings`,
    Stream: `stream/:id`
  };

  static readonly Auth = {
    Login: `login`,
    Signup: `signup`,
    ForgotPassword: `forgot-password`,
    AccountVerification: `account-verification`,
    ChangePassword: `change-password`,
  };

  static readonly Programs = {
    Manage: `manage-programs`,
    Create: `create`,
    Update: `update/:id`,
  };

  static readonly Subscriptions = {
    Plans: `plans`
  }
}
