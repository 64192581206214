import React, { useEffect, useState } from "react";
import {
  Box,
  Theme,
  Chip,
  useTheme,
  Button,
  useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import AddToPlaylistModal from "../../../../components/AddToPlaylistModal";
import { Video } from "../../../../types";
import { setCurrentVideo } from "../../../../redux/state/videoSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { formatVideoLength } from "../../../../utils/formatVideoLength";
import { useContinueWatching } from "../../../../hooks/useContinueWatching";
import { useAlert } from "../../../../contexts/AlertContext";
import { RootState } from "../../../../redux/store";

const useStyles = makeStyles<Theme>((theme) => ({
  heroContainer: {
    backdropFilter: "blur(0px)",
    WebkitBackdropFilter: "blur(0px)", // for Safari
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #FFFFFF 100%)`, // Updated gradient
      backgroundSize: "cover",
      backgroundPosition: "center",
      zIndex: -1,
    },
    [theme.breakpoints.up("xs")]: {
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(6),
    },
    [theme.breakpoints.up("sm")]: {},
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(15),
      paddingTop: theme.spacing(15),
    },
  },
  heading: {
    textAlign: "left",
    // color: "#121212",
    fontSize: "3rem",
    color: "white",
    textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
    letterSpacing: "0px",
    opacity: 1,
    fontFamily: "Roboto",
    marginTop: 0,
  },
  chip: {
    "& .MuiChip-label": {
      color: "white",
      textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
      lineHeight: "50px",
      fontFamily: "Roboto",
      letterSpacing: "0px",
    },
  },
  chipContainer: {
    display: "flex",
    gap: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  description: {
    textAlign: "left",
    // color: "#121212",
    fontSize: "1.4rem",

    color: "white",
    textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
    letterSpacing: "0px",
    lineHeight: "35px",
    opacity: 1,
    [theme.breakpoints.up("xs")]: {
      maxWidth: "90%",
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "60%",
    },
  },
  childContainer: {
    overflowX: "hidden",
    width: `calc(100% - ${theme.spacing(2)})`, // Adjust width based on padding
    [theme.breakpoints.up("xs")]: {
      left: theme.spacing(2),
      width: "100%",
      // width: `calc(100% - ${theme.spacing(2)})`, // Adjust for XS breakpoint
    },
    [theme.breakpoints.up("md")]: {
      left: theme.spacing(15),
      width: "100%",
      // width: `calc(100% - ${theme.spacing(15)})`, // Adjust for MD breakpoint
    },
    marginTop: theme.spacing(5),
  },
}));

export type HeroDetails = {
  heading: string;
  filmLength: string;
  filmYear: string;
  filmCategory: string;
  filmDescription: string;
  image: string;
};

interface HeroProps {
  heroDetails: Video;
  children?: JSX.Element;
  isChildren: boolean;
}

const Hero: React.FC<HeroProps> = ({ heroDetails, children, isChildren }) => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const classes = useStyles({ isChildren });
  const { user } = useSelector((state: RootState) => state.user);

  const { features } = user;
  const shouldShowPlaylist = features.playlist;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { info } = useAlert();
  const { currentProgram } = useSelector((state: RootState) => state.program);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const { fetchVideoContinueWatching, create } = useContinueWatching(
    currentProgram.id
  );

  const handlePlayVideo = async () => {
    if (heroDetails && heroDetails.medias.length) {
      try {
        // Fetch the latest video continue watching state
        const { data: updatedData } = await fetchVideoContinueWatching({
          program_id: currentProgram.id,
          video_id: heroDetails.id,
          sort: "asc",
          field: "created_at",
          page_number: 1,
          page_size: 1,
        });

        if (updatedData && updatedData.count > 0) {
          const continueWatchingEntry = updatedData.data[0];
          // Set the video with the updated progress
          dispatch(
            setCurrentVideo({
              video: heroDetails,
              programId: continueWatchingEntry.program_id,
              progress: continueWatchingEntry.current_position,
              total: heroDetails.medias[0].length,
            })
          );
        } else {
          // If the video is not in the continue watching list, create a new entry
          await create({
            program_id: currentProgram.id,
            video_id: heroDetails.id,
            current_position: 0,
            is_complete: false,
          });

          dispatch(
            setCurrentVideo({
              video: heroDetails,
              programId: currentProgram.id,
              progress: 0,
              total: heroDetails.medias[0].length,
            })
          );
        }

        navigate(`/browse/stream/${heroDetails.id}`);
      } catch (error) {
        console.error("Error handling play video:", error);
      }
    } else {
      info("Video not available");
    }
  };

  // Compute padding based on the presence of children
  const paddingBottom = children ? (isMdUp ? "10%" : "15%") : "5";

  return (
    <Box
      className={classes.heroContainer}
      style={{
        backgroundImage: `url(${heroDetails?.thumbnails?.[0]?.url})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <h1 className={classes.heading}>{heroDetails?.title ?? ""}</h1>
      <div className={classes.chipContainer}>
        <Chip
          label={`${heroDetails?.publish_date.slice(0, 4)}`}
          className={classes.chip}
          sx={{ backgroundColor: "#EC674C40", borderRadius: 2 }}
        />

        {heroDetails?.category && heroDetails?.category.length
          ? heroDetails?.category.map((category, index) => (
              <Chip
                key={index}
                label={`${category}`}
                className={classes.chip}
                sx={{ backgroundColor: "#EC674C40", borderRadius: 2 }}
              />
            ))
          : ""}
        {heroDetails?.medias && heroDetails?.medias.length ? (
          heroDetails?.medias[0].length ? (
            <Chip
              label={formatVideoLength(heroDetails?.medias[0].length)}
              className={classes.chip}
              sx={{ backgroundColor: "#EC674C40", borderRadius: 2 }}
            />
          ) : (
            ""
          )
        ) : (
          ""
        )}
      </div>
      <p className={classes.description}>{heroDetails?.description}</p>
      <div style={{ display: "flex", justifyContent: "flex-start" }}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          sx={{
            width: "fit-content",
            fontSize: isMdUp ? "1rem" : "inherit",
            background: "#EC674C",
            paddingX: isMdUp ? theme.spacing(6) : theme.spacing(2),
            paddingy: theme.spacing(1),
            mr: theme.spacing(2),
            textTransform: "none",
            borderRadius: isMdUp ? "40px" : "30px",
            "&:hover": {
              background: "#EC674C",
              boxShadow: "none",
            },
            boxShadow: "none",
          }}
          onClick={handlePlayVideo}
        >
          Watch Now
        </Button>
        {shouldShowPlaylist && (
          <Button
            variant="outlined"
            size="large"
            sx={{
              width: "fit-content",
              fontSize: isMdUp ? "1rem" : "inherit",
              paddingX: isMdUp ? theme.spacing(6) : theme.spacing(2),
              paddingy: theme.spacing(1),
              textTransform: "none",
              borderRadius: isMdUp ? "40px" : "30px",
              borderColor: "white",
              color: "white",
              "&:hover": {
                boxShadow: "none",
                background: "none",
                borderColor: "white",
              },
            }}
            onClick={handleOpenModal}
          >
            Add to playlist
          </Button>
        )}
      </div>
      <AddToPlaylistModal
        open={isModalOpen}
        onClose={handleCloseModal}
        id={heroDetails?.id}
        _id={heroDetails?._id}
      />
      <div className={classes.childContainer}>{children}</div>
    </Box>
  );
};

export default Hero;
