import Hero from "./components/Hero";
import ContinueWatching from "./components/ContinueWatching";
import Recommendations from "./components/Recommendations";
import CategoryList from "../CategoriesPage/components/CategoryList";
import { useGetCategoriesQuery } from "../../redux/api/categoryApi";
import {
  useGetAllVideosQuery,
  useGetRecommendedVideosQuery,
} from "../../redux/api/videoApi";
import { useEffect } from "react";
import LoadingView from "../../components/LoadingView";
import ErrorView from "../../components/ErrorView";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import {
  ProgramVideoProgress,
  loadProgressFromStorage,
} from "../../redux/state/videoProgressSlice";
import CategoryListSkeleton from "../../components/Skeletons/CategoryListSkeleton";
import HeroSkeleton from "../../components/Skeletons/HeroSkeleton";
import RecommendationsSkeleton from "../../components/Skeletons/RecommendationsSkeleton";
import { Video } from "../../types";
import { useContinueWatching } from "../../hooks/useContinueWatching";

const LandingPage = () => {
  const { currentProgram } = useSelector((state: RootState) => state.program);
  const { user } = useSelector((state: RootState) => state.user);
  const { features } = user;

  const shouldShowRecommendations = features.recommendations;

  const dispatch = useDispatch();

  const {
    data: categoriesData,
    isError: isCategoriesError,
    isLoading: isFetchingCategories,
  } = useGetCategoriesQuery({
    page_number: 1,
    page_size: 10,
    field: "created_at",
    sort: "asc",
  });

  const {
    data: recommendationsData,
    isLoading: isFetchingRecommendations,
    isError: isRecommendationsError,
  } = useGetRecommendedVideosQuery({
    page_size: 10,
    page_number: 1,
    sort: "asc",
    published: true,
  });

  const {
    data: allVideos,
    isLoading: iaFetchingAllVideos,
    isError: isAllVideosError,
  } = useGetAllVideosQuery({
    page_size: 10,
    page_number: 1,
    sort: "asc",
    field: "created_at",
    published: true,
  });

  // Use the custom useContinueWatching hook
  const {
    continueWatchingData,
    isFetching: isFetchingContinueWatching,
    fetchError: isContinueWatchingError,
  } = useContinueWatching(currentProgram?.id);

  const featuredVideo: Video =
    recommendationsData?.count > 0
      ? recommendationsData?.data[0]
      : allVideos?.data[0];

  const formatCateoryName = (name: string) => {
    return name.toLowerCase().replace(/ /g, "-");
  };

  // if (isFetchingRecommendations || isFetchingCategories) {
  //   return <LoadingView />;
  // }

  if (isCategoriesError || isRecommendationsError) {
    return <ErrorView message="Error Loading page" type="error" />;
  }

  return (
    <div>
      {isFetchingRecommendations ? (
        <HeroSkeleton />
      ) : (
        <Hero
          heroDetails={featuredVideo}
          isChildren={continueWatchingData && continueWatchingData.count > 0}
        >
          {continueWatchingData && continueWatchingData.count > 0 && (
            <ContinueWatching continueWatchingData={continueWatchingData} />
          )}
        </Hero>
      )}

      {!shouldShowRecommendations ? null : isFetchingRecommendations ? (
        <RecommendationsSkeleton />
      ) : recommendationsData?.data?.length > 0 ? (
        <Recommendations videos={recommendationsData.data} />
      ) : (
        <RecommendationsSkeleton />
      )}

      {isFetchingCategories ? (
        <CategoryListSkeleton isChild={false} />
      ) : categoriesData?.data?.length > 0 ? (
        categoriesData.data.map((category) => (
          <CategoryList
            key={category.id}
            category={category}
            sectionId={formatCateoryName(category.name)}
          />
        ))
      ) : (
        <CategoryListSkeleton isChild={false} />
      )}
    </div>
  );
};

export default LandingPage;
