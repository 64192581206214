// components/Skeletons/NotificationSkeleton.jsx
import React from 'react';
import { Card, CardMedia, CardContent, Typography, Box, Skeleton, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import useBreakpoints from "../../hooks/useBreakPoints";

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    boxShadow: 'none',
    marginBottom: theme.spacing(2),
  },
  cardContent: {
    "&:last-child": {
      paddingBottom: theme.spacing(2),
    },
    paddingTop: theme.spacing(2),
    paddingLeft: 0,
    paddingRight: 0,
  },
  dot: {
    display: 'inline-block',
    width: theme.spacing(1),
    height: theme.spacing(1),
    borderRadius: '50%',
    marginLeft: theme.spacing(1),
  },
  seenDot: {
    backgroundColor: 'gray',
  },
  unseenDot: {
    backgroundColor: 'orange',
  },
  content: {
    flexGrow: 1,
  },
}));

const NotificationSkeleton = () => {
  const classes = useStyles();
  const { isXs, isSm, isMd, isLg, isXl } = useBreakpoints();

  const contentHeight = isXl ? 304 : isLg ? 250 : isMd ? 200 : isSm ? 150 : 240;
  const contentWidth = isXl ? 362 : isLg ? 310 : isMd ? 428 : isSm ? 328 : 228;

  return (
    <Card className={classes.card}>
      <CardMedia sx={{ height: contentHeight, borderRadius: 10 }}>
        <Skeleton variant="rectangular" width="100%" height="100%" />
      </CardMedia>
      <CardContent className={classes.cardContent}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Skeleton variant="text" width={80} />
            <Box className={`${classes.dot} ${classes.unseenDot}`} />
          </Box>
          <Box display="flex" alignItems="center">
            <Skeleton variant="circular" width={24} height={24} />
            <Skeleton variant="circular" width={24} height={24} />
          </Box>
        </Box>
        <Skeleton variant="text" width="60%" />
        <Skeleton variant="text" width="80%" />
        <Skeleton variant="text" width="40%" />
      </CardContent>
    </Card>
  );
};

export default NotificationSkeleton;
