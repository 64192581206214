// Interface for the login request body
export interface LoginPayload {
  email: string;
  password: string;
}

// Interface for the login response
export interface LoginResponse {
  access_token: string;
  refresh_token: string;
  // The refresh_token is set via HTTP-only cookie, not returned in the body
}

export interface IFeatures {
  video_resource: boolean;
  recommendations: boolean;
  playlist: boolean;
}

// Interface for user data retrieved after successful login
export interface User {
  name: string;
  free_trial?: boolean;
  industry: string;
  id: string;
  created_at: string; // ISO date string format
  email: string;
  confirmed: boolean;
  stripe?: {
    customer: string;
    subscription: string;
  };
  features?: IFeatures;
  devices?: number;
  free_trial_end_date?: string | null; // Added
  free_trial_banner_dismissed?: boolean; // Added
}

// Interface for the signup request body
export interface SignUpPayload {
  email: string;
  name: string;
  industry: string;
  password: string;
}

// Interface for the account information in the signup response
export interface Company {
  name: string;
  industry: string;
  id: string;
  created_at: string; // ISO date string format
  email: string;
  confirmed: boolean;
  stripe?: {
    customer: string;
    subscription: string;
  };
}

// Interface for the signup response
export interface SignUpResponse {
  success: boolean;
  account: Company;
}

export interface RecoverPasswordPayload {
  email: string;
}

export interface RecoverPasswordResponse {
  message: string;
}

export interface UpdateCompanyPayload {
  name?: string;
  industry?: string;
  password?: string;
}

export interface Program {
  nickname: string;
  company_id: string;
  id: string;
  created_at: string;
  pin?: string;
  language: string;
  picture?: {
    url: string;
  };
}

export interface ReduxProgram {
  nickname: string;
  company_id: string;
  id: string;
  created_at: string;
  language: string;
  picture?: {
    url: string;
  };
}

export interface GetProgramsParams {
  page_size: number;
  page_number: number;
  sort: "asc" | "desc";
  field: "created_at" | "nickname" | "language";
  company_id: string;
}

export interface ProgramListResponse {
  data: Program[];
  count: number;
}

export interface CreateProgramInput {
  nickname: string;
  company_id: string;
  pin?: string;
  language: string;
}

export interface UploadPictureResponse {
  message: string;
}

// Define types for Playlist and related operations
export interface Playlist {
  id: string;
  _id: string;
  name: string;
  description: string;
  created_at: string;
  program_id: string;
  videos: { id: string }[];
}

export interface GetMyPlaylistsParams {
  page_size: number;
  page_number: number;
  sort: string;
  field: string;
  program_id?: string;
}

export interface PlaylistResponse {
  data: Playlist;
  success: boolean;
}

export interface PlaylistListResponse {
  data: Playlist[];
  count: number;
}

export interface CreatePlaylistInput {
  name: string;
  description: string;
  program_id: string;
}

export interface UpdatePlaylistInput {
  name?: string;
  description?: string;
  videos?: { id: string }[];
}

// Define a type for the structure of a video
// Video structure
export interface Video {
  _id: string;
  id: string;
  title: string;
  upload: { process: number; media_id: string };
  description: string;
  thumbnails: Thumbnail[];
  publish_date: string;
  is_visible: boolean;
  medias: Media[];
  category: string[];
  created_at: string;
  updated_at: string;
  version: number;
  instructors: InstructorSummary[]; // Assuming additional instructor info can be included
  sponsors: { id: string; _id: string }[]; // Assuming additional sponsor info can be included
  resources: Resource[];
  copywrites: Copywrite[];
  tags: string[];
  captions?: { url: string; language: string }[];
}

interface Resource {
  url: string;
  file_type: string;
  name: string;
  _id: string;
}

interface Copywrite {
  url: string;
  file_type: string;
  _id: string;
}

// Define types for sub-structures if necessary
interface Thumbnail {
  _id: string;
  url: string;
  type: string;
}

interface Media {
  _id: string;
  url: string;
  type: string;
  resolution: "";
  length: number;
}

interface InstructorSummary {
  name: string;
  role: string;
  _id: string;
  id: string;
}

export interface Instructor {
  id: string;
  _id: string;
  title?: string;
  first_name: string;
  last_name: string;
  image?: {
    url: string;
  };
  bio?: string;
  expertise_areas?: string[];
  qualifications?: string[];
  created_at: string;
  updated_at: string;
}

interface SponsorId {
  id: string;
}

// Define a type for the video list response
export interface VideoListResponse {
  data: Video[];
  count: number;
}

// Define types for query parameters
export interface VideoQueryParams {
  page_size: number;
  page_number: number;
  sort: "asc" | "desc";
  field?: string;
  title?: string;
  category?: string;
  published?: boolean;
}

export interface VideoSearchQueryParams {
  page_size: number;
  page_number: number;
  sort: string;
  field: string;
  q: string;
  category?: string;
  published?: boolean;
}

// Define a type for the response of a single video
export interface VideoResponse {
  data: Video;
  success: boolean;
}

export interface CategoryQueryParams {
  page_size: number;
  page_number: number;
  sort: "asc" | "desc";
  field: "created_at" | "name";
}

export interface Industry {
  id: string;
  _id: string;
  name: string;
  created_at: string;
}

export interface IndustryQueryParams {
  page_size: number;
  page_number: number;
  sort: "asc" | "desc";
  field: "created_at" | "name";
}

export interface Category {
  name: string;
  description: string;
  id: string;
  created_at: string;
  cover_image: Array<{
    url: string;
    type: "landscape" | "portrait";
  }>;
}

export interface CategoryListResponse {
  data: Category[];
  count: number;
}

// Types and Interfaces for Sponsor API

// Interface for the sponsor's contact
export interface SponsorContact {
  name: string;
  position: string;
  email: string;
  phone_number: string;
}

// Interface for the sponsor's social media information
export interface SponsorSocial {
  url: string;
  platform: string;
}

// Interface for the sponsor's location
export interface SponsorLocation {
  city: string;
  street: string;
  state: string;
  country: string;
}

// Interface for the main sponsor object
export interface Sponsor {
  id: string;
  name: string;
  description: string;
  email: string;
  mobile: string;
  website: {
    url: string;
  };
  logo: {
    url: string;
  };
  contacts: SponsorContact[];
  social: SponsorSocial[];
  location: SponsorLocation;
  created_at: string; // ISO date string
}

// Response type for fetching sponsor data
export interface SponsorResponse {
  data: Sponsor;
}

// Define types for query parameters to get all notifications
export interface GetAllNotificationsParams {
  page_size: number;
  page_number: number;
  program_id?: string; // Optional
}

// Define types for body parameters to mark all notifications as seen
export interface SeenNotificationsParams {
  program_id?: string; // Optional
  date?: string; // Optional, format: "YYYY-MM-DDTHH:mm:ssZ"
}

// Define type for a single notification
export interface Notification {
  id: string;
  subject: string;
  description: string;
  type: "info" | "warn" | "error";
  seen: boolean;
  interact: boolean;
  created_at: string; // format: "YYYY-MM-DDTHH:mm:ssZ"
  _id: string;
  company_id: string;
  video: {
    id: string;
    progress: number;
  };
  version: number;
}

// types.ts
export interface ContinueWatching {
  _id: string;
  program_id: string;
  video_id: string;
  current_position: number;
  is_complete: boolean;
  created_at: string;
  updated_at: string;
  id: string;
  version: number;
}

export interface ContinueWatchingQueryParams {
  page_size: number;
  page_number: number;
  sort: string;
  field: string;
  program_id: string;
  video_id?: string;
}

export interface ContinueWatchingCreatePayload {
  program_id: string;
  video_id: string;
  current_position: number;
  is_complete: boolean;
}

export interface ContinueWatchingUpdatePayload {
  current_position: number;
  is_complete: boolean;
}

export type Interval = "day" | "week" | "month" | "year";

export interface StripeInfo {
  price_id: string; // Assuming price_id is always present in Stripe info
}

export interface IPrice {
  id?: string; // Optional if not always provided
  stripe: StripeInfo;
  amount: number; // Stored in cents
  interval: Interval;
  interval_count: number;
  trial_days: number;
  deleted?: boolean; // Indicates if the price is deleted
}

// Define the Subscription Plan interface
export interface SubscriptionPlan {
  id: string; // Optional for creation; required for existing subscriptions
  name: string;
  description: string;
  prices: IPrice[];
  devices: number;
  features: IFeatures;
  version: number;
  active: boolean;
  deleted: boolean;
  created_at: string; // ISO date string
  updated_at: string; // ISO date string
}

// Define the Subscription interface
export interface Subscription {
  id: string;
  customer: string;
  stripe_id: string;
  expires_at?: string;
  canceled_at?: string;
  starts_at?: string;
  free_trial?: string;
  subscription_plan_id: string;
  status: "active" | "pause" | "cancel" | "pending" | "trial";
  company?: {
    id?: string;
  };
  details?: {
    video_resource: boolean;
    recommendations: boolean;
    playlist: boolean;
    devices: number;
  };
  created_at: string;
  updated_at: string;
  version: number;
}

// Define the interfaces for query parameters for getting all subscription plans
export interface GetAllSubscriptionsParams {
  page_size: number;
  page_number: number;
  active: boolean;
}

interface SessionElement {
  currency: string;
  company: {
    id: string;
    stripe_customer: string;
  };
  subscription: {
    price_id: string;
  };
  urls: {
    success: string;
    cancel: string;
  };
  checkout_url: string;
  client_reference_id: string | null;
  id: string;
  created_at: string; // or Date if you want to handle it as a Date object
  updated_at: string; // or Date
}

export interface SessionResponse {
  element: SessionElement;
}

export interface CreateSessionPayload {
  price_id: string;
  free_trail_days?: number;
}

export interface VideoViewAnalytics {
  company_id: string;
  video_id: string;
  id: string;
  retention_rate?: number; // Percentage of the video viewed by the user
  device_type?: "mobile" | "desktop" | "tablet" | "tv"; // Type of device used for viewing
  platform?: "firestick" | "ios" | "android" | "chrome" | "safari" | string; // Platform used for viewing
  traffic_source?: "link" | "search" | "social media"; // How the viewer arrived at the video
  location?: {
    city?: string;
    state?: string;
    country?: string;
  };
  created_at: string;
  updated_at: string;
  version: number;
}

/**
 *search: If the user found the video through a search engine (e.g., Google) or a search functionality within your app.
 *social: If the user clicked on a link shared via social media platforms (e.g., Facebook, Twitter).
 *link: If the user clicked on an internal link, such as a video thumbnail, a featured section, or a recommended video within your app.
 */
export interface VideoViewPayload {
  company_id: string;
  video_id: string;
  program_id: string;
  retention_rate?: number; // Percentage of the video viewed by the user
  device_type?: "mobile" | "desktop" | "tablet" | "tv"; // Type of device used for viewing
  platform?: "firestick" | "ios" | "android" | "chrome" | "safari" | string; // Platform used for viewing
  traffic_source?: "link" | "search" | "social media"; // How the viewer arrived at the video
  location?: {
    city?: string;
    state?: string;
    country?: string;
  };
  view_id?: string;
}

export interface VideoViewCount {
  count: number;
  date: string | undefined;
}

export interface VideoViewCountParams {
  video_id?: string;
  company_id?: string;
  start_date: string | undefined;
  end_date: string | undefined;
}

export interface VideoViewAnalyticsParams {
  page_size: number;
  page_number: number;
  sort: "asc" | "dec";
  field: "created_at";
  video_id?: string;
  company_id?: string;
}

export interface UpdateVideoView {
  retention_rate?: number; // Percentage of the video viewed by the user
  device_type?: "mobile" | "desktop" | "tablet" | "tv"; // Type of device used for viewing
  platform?: "firestick" | "ios" | "android" | "chrome" | "safari" | string; // Platform used for viewing
  traffic_source?: "link" | "search" | "social media"; // How the viewer arrived at the video
  location?: {
    city?: string;
    state?: string;
    country?: string;
  };
}

// Define any additional types or interfaces as required by your API

export enum Levels {
  app = "app",
  personal = "personal",
}
