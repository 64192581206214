import * as React from "react";
import Stack from "@mui/material/Stack";
import Slider from "@mui/material/Slider";

function valuetext(value: number) {
  return `${value}%`;
}

export default function VolumeSlider({
  volume,
  onVolumeChange,
}: {
  volume: number;
  onVolumeChange: (event: Event, newValue: number | number[]) => void;
}) {
  return (
    <Stack sx={{ height: 200, py: 2 }} direction="row">
      <Slider
        aria-label="Volume"
        orientation="vertical"
        getAriaValueText={valuetext}
        valueLabelDisplay="auto"
        value={volume * 100} // Assuming volume is a fraction (0-1), convert it to percentage
        onChange={(event, newValue) => onVolumeChange(event, newValue )} // Convert back to fraction for volume control
        min={0}
        max={100}
        sx={{backgroundColor: "transparent"}}
      />
    </Stack>
  );
}
