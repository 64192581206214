import React, { useState } from "react";
import {
  Card,
  CardActions,
  CardContent,
  Typography,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Chip,
  CardActionArea,
  Theme,
  useTheme,
  CardMedia,
} from "@mui/material";
import { Instructor } from "../../../types";
import { makeStyles } from "@mui/styles";
import useBreakpoints from "../../../hooks/useBreakPoints";

interface InstructorCardProps {
  instructor: Instructor;
}

const placeholderImage =
  "https://www.treasury.gov.ph/wp-content/uploads/2022/01/male-placeholder-image.jpeg"; // Add your placeholder image path here


const useStyles = makeStyles((theme: Theme) => ({
  // Style for the card container
  card: {
    position: "relative",
    backdropFilter: "blur(0px)",
    WebkitBackdropFilter: "blur(0px)",
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      background:
        "transparent linear-gradient(180deg, #FFFFFF00 0%, #000000 100%)",
      backgroundSize: "cover",
      backgroundPosition: "center",
      zIndex: 0,
    },
    transition: "transform 0.3s ease, box-shadow 0.3s ease", // Smooth transition for transform and shadow
    "&:hover": {
      // outline: "4px solid #EC674C",
      // outlineOffset: "-2px",
      cursor: "pointer",
    },
  },
  // Style for the media/image part of the card
  media: {
    height: "100%",
  },
  // Style for the container holding the information
  infoContainer: {
    position: "absolute",
    bottom: 5,
    left: theme.spacing(2),
    width: "90%",
  },
  dialogAvatar: {
    width: "100%",
    height: 300,
    objectFit: "cover",
  },
  dialogContent: {
    padding: theme.spacing(2),
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      display: "none", // Hide the scrollbar for Webkit browsers
    },
    "-ms-overflow-style": "none", // IE and Edge
    "scrollbar-width": "none", // Firefox
  },
  dialogSectionTitle: {
    fontWeight: "bold",
    marginTop: theme.spacing(2),
  },
  // Style for the title text
  title: {
    color: "white",
    marginBottom: theme.spacing(1),
    fontWeight: "normal",
    textAlign: "left",
  },
}));

const InstructorCard: React.FC<InstructorCardProps> = ({ instructor }) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const { isSm, isMd, isLg, isXl } = useBreakpoints();

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  // Calculates responsive sizing for the card based on breakpoints
  const cardHeight = isXl ? 302 : isLg ? 252 : isMd ? 252 : isSm ? 175 : 175;
  const cardWidth = isXl ? 325 : isLg ? 275 : isMd ? 275 : isSm ? 225 : 225;

  return (
    <>
      <Card
        className={classes.card}
        sx={{ width: cardWidth, height: cardHeight, borderRadius: "10px" }}
        onClick={handleOpenDialog}
      >
        <CardMedia
          className={classes.media}
          image={instructor?.image?.url ?? placeholderImage }
          title={`${instructor?.first_name ?? ""} ${instructor?.last_name ?? ""} Profile Photo`}
        />
        <Box className={classes.infoContainer}>
          <h2
            className={classes.title}
          >{`${instructor?.title ?? ""} ${instructor?.first_name ?? ""} ${instructor?.last_name ?? ""}`}</h2>
        </Box>
      </Card>
      <Dialog
        open={open}
        onClose={handleCloseDialog}
        maxWidth="xs"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 5,
            height: "auto",
            maxHeight: "90vh"
          },
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "auto",
            position: "relative",
            "&::before": {
              content: '""',
              position: "absolute",
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              background:
                "transparent linear-gradient(180deg, #FFFFFF00 0%, #000000 100%)",
              backgroundSize: "cover",
              backgroundPosition: "center",
              zIndex: 0,
            },
          }}
        >
          <CardMedia
            component="img"
            image={instructor?.image?.url ?? placeholderImage}
            alt={`${instructor?.first_name ?? ""} ${instructor?.last_name ?? ""}`}
            className={classes.dialogAvatar}
          />
          <Typography
            variant="h5"
            className={classes.title}
            sx={{
              position: "absolute",
              bottom: 5,
              left: theme.spacing(2),
              width: "90%",
            }}
          >
            {`${instructor?.title ?? ""} ${instructor?.first_name ?? ""} ${instructor?.last_name ?? ""}`}
          </Typography>
        </Box>

        <DialogContent className={classes.dialogContent}>
          <Typography variant="body1" gutterBottom>
            {instructor?.bio ?? "Bio Unavailable"}
          </Typography>

          {instructor?.expertise_areas && (
            <Box>
              <Typography
                variant="subtitle1"
                className={classes.dialogSectionTitle}
                sx={{textDecoration: "underline"}}
              >
                Expertise
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {instructor?.expertise_areas.join(", ") ?? "Expertise Areas Unavailable"}
              </Typography>
            </Box>
          )}

          {instructor?.qualifications && (
            <Box>
              <Typography
                variant="subtitle1"
                className={classes.dialogSectionTitle}
                sx={{textDecoration: "underline"}}
              >
                Qualifications
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {instructor?.qualifications.join(", ") ?? "Qualifications Unavailable"}
              </Typography>
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default InstructorCard;
