// endpoints.ts

// Company Authentication Endpoints
export const LOGIN_USER_ENDPOINT = `/company/login`;  // Endpoint for user login
export const SIGNUP_USER_ENDPOINT = `/company/signup`;  // Endpoint for user signup
export const RECOVER_PASSWORD_ENDPOINT = `/company/recover-password`;  // Endpoint for password recovery
export const GET_NEW_ACCESS_TOKEN_ENDPOINT = `/company/access-token`; // Endpoint to get new access token

// Company Management Endpoints
export const GET_CURRENT_COMPANY_ENDPOINT = `/company`;  // Endpoint to get current company details
export const UPDATE_CURRENT_COMPANY_ENDPOINT = `/company`;  // Endpoint to update current company details
export const DELETE_CURRENT_COMPANY_ENDPOINT = `/company`;  // Endpoint to delete current company
export const SEND_CONFIRMATION_CODE_ENDPOINT = `/company/confirmation`;
export const CONFIRM_COMPANY_ENDPOINT = `/company/confirm`;
export const CONFIRM_PASSWORD_ENDPOINT = `/company/verify-password`;

// Program Management Endpoints
export const GET_PROGRAMS_ENDPOINT = `/programs`;  // Endpoint to get a list of programs
export const GET_PROGRAM_BY_ID_ENDPOINT = (programId: string) => `/program/${programId}`;  // Endpoint to get a specific program by ID
export const CREATE_PROGRAM_ENDPOINT = `/program`;  // Endpoint to create a new program
export const UPDATE_PROGRAM_ENDPOINT = (programId: string) => `/program/${programId}`;  // Endpoint to update a specific program
export const DELETE_PROGRAM_ENDPOINT = (programId: string) => `/program/${programId}`;  // Endpoint to delete a specific program
export const UPLOAD_PICTURE_ENDPOINT = (programId: string) => `/program/${programId}/picture-upload`;  // Endpoint to upload a picture for a program

// Playlist Management Endpoints
export const CREATE_PLAYLIST_ENDPOINT = '/playlist';  // Endpoint to create a new playlist
export const GET_MY_PLAYLISTS_ENDPOINT = '/playlists';  // Endpoint to retrieve playlists
export const GET_PLAYLIST_BY_ID_ENDPOINT = (playlistId: string) => `/playlist/${playlistId}`;  // Endpoint to get a specific playlist by ID
export const UPDATE_PLAYLIST_BY_ID_ENDPOINT = (playlistId: string) => `/playlist/${playlistId}`;  // Endpoint to update a specific playlist
export const DELETE_PLAYLIST_BY_ID_ENDPOINT = (playlistId: string) => `/playlist/${playlistId}`;  // Endpoint to delete a specific playlist

// Video Management Endpoints
export const GET_ALL_VIDEOS_ENDPOINT = '/videos';
export const SEARCH_VIDEOS_ENDPOINT = '/video/search';
export const RECOMMENDED_VIDEOS_ENDPOINT = '/video/recommend';
export const GET_VIDEO_BY_ID_ENDPOINT = (videoId: string) => `/video/${videoId}`;

// Category Management Endpoints
export const GET_ALL_CATEGORIES_ENDPOINT = '/categories';

// Sponsor Management Endpoints
export const  GET_SPONSOR_BY_ID_ENDPOINT = (sponsorId: string) => `/sponsor/${sponsorId}`;

// Instructor Management Endpoints
export const  GET_INSTRUCTOR_BY_ID_ENDPOINT = (instructorId: string) => `/instructor/${instructorId}`;

// Industry Management Endpoints
export const GET_ALL_INDUSTRIES = `/industries`;

// Notification Management Endpoints
export const GET_ALL_MY_NOTIFICATIONS_ENDPOINT = '/notifications';  // Endpoint to get all notifications
export const SEEN_ALL_MY_NOTIFICATIONS_ENDPOINT = '/notification/seen';  // Endpoint to mark all notifications as seen
export const DELETE_NOTIFICATION_ENDPOINT = (notificationId: string) => `/notification/${notificationId}`;  // Endpoint to delete a specific notification
export const INTERACT_WITH_NOTIFICATION_ENDPOINT = (notificationId: string) => `/notification/interact/${notificationId}`;  // Endpoint to interact with a specific notification

// Subscription Management Endpoints
export const GET_SUBSCRIPTION_PLAN_ENDPOINT = (subscriptionPlanId: string) => `/subscription-plan/${subscriptionPlanId}`;  // Endpoint to get a specific subscription plan by ID
export const GET_ALL_SUBSCRIPTION_PLANS_ENDPOINT = '/subscription-plans';  // Endpoint to get all subscription plans
export const GET_SUBSCRIPTION_ENDPOINT = (subscriptionId: string) => `/subscription/${subscriptionId}`;  // Endpoint to get a specific subscription by ID
export const SUBSCRIBE_SUBSCRIPTION_ENDPOINT = '/subscription/subscribe';  // Endpoint to subscribe to a subscription plan
export const UNSUBSCRIBE_SUBSCRIPTION_ENDPOINT = (subscriptionId: string) => `/subscription/unsubscribe/${subscriptionId}`;  // Endpoint to unsubscribe from a subscription plan

// Continue Watching Management Endpoints
export const GET_CONTINUE_WATCHING = "/continue-watching";
export const CREATE_CONTINUE_WATCHING = "/continue-watching";
export const UPDATE_CONTINUE_WATCHING = "/continue-watching";
export const DELETE_CONTINUE_WATCHING = "/continue-watching";

