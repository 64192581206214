import React, { useState } from "react";
import Playlist from "./components/Playlist";
import {
  Theme,
  Typography,
  CircularProgress,
  Skeleton,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useGetMyPlaylistsQuery } from "../../redux/api/playlistApi"; // Import the hook
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import LoadingView from "../../components/LoadingView";
import ErrorView from "../../components/ErrorView";
import { useTranslation } from "react-i18next";
import PlaylistSkeleton from "../../components/Skeletons/PlaylistSkeleton";

const useStyles = makeStyles<Theme>((theme) => ({
  heading: {
    marginTop: theme.spacing(5),
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(15),
    },
    marginBottom: 2,
    color: "#121212",
  },
  container: {
    marginTop: theme.spacing(5),
    paddingLeft: theme.spacing(2),

    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(15),
    },
  },
}));

const PlaylistPage = () => {
  const classes = useStyles();
  const theme = useTheme();

  const [page, setPage] = useState(1); // Current page number
  const [pageSize, setPageSize] = useState(10); // Number of items per page
  const [sort, setSort] = useState("asc"); // Sorting order
  const [sortField, setSortField] = useState("created_at"); // Field to sort by
  const { currentProgram } = useSelector((state: RootState) => state.program);
  const { t } = useTranslation();

  const program = useSelector(
    (state: RootState) => state.program.currentProgram
  );

  // Use the hook with query parameters
  const {
    data: playlistResponse,
    isLoading,
    isError,
  } = useGetMyPlaylistsQuery({
    page_size: pageSize,
    page_number: page,
    sort,
    field: sortField,
    program_id: currentProgram?.id,
  });

  if (isLoading) {
    return (
      <div>
        <div className={classes.container}>
          <Skeleton
            variant="text"
            width={200}
            height={40}
            sx={{
              marginTop: theme.spacing(5),
              paddingLeft: theme.spacing(2),
              [theme.breakpoints.up("md")]: {
                paddingLeft: theme.spacing(15),
              },
              marginBottom: 2,
            }}
          />
        </div>

        <PlaylistSkeleton />
        <PlaylistSkeleton />
        <PlaylistSkeleton />
      </div>
    );
  }

  if (isError) {
    return <ErrorView message={t("PlaylistPage.FetchError")} type="error" />;
  }

  return (
    <div>
      <h1 className={classes.heading}>{t("PlaylistPage.Title")}</h1>
      {playlistResponse &&
      playlistResponse.data &&
      playlistResponse.data.length ? (
        playlistResponse.data.map((playlist, index) => (
          <Playlist key={index} playlist={playlist} />
        ))
      ) : (
        <ErrorView message={t("PlaylistPage.NoNotifications")} type="info" />
      )}
    </div>
  );
};

export default PlaylistPage;

const films = [
  {
    id: "1",
    filmName: "Art is Life",
    category: "Art Therapy",
    filmLength: "1h 55m",
    image:
      "https://static.wixstatic.com/media/0a46f5_2940b659be324c4295142f64bb204de3~mv2.png/v1/crop/x_24,y_0,w_507,h_1080/fill/w_507,h_404,al_c,q_85,enc_auto/Art%20Therapy.png",
  },
  {
    id: "2",
    filmName: "Piano Vive w/John",
    category: "Music Therapy",
    filmLength: "1h 55m",
    image:
      "https://www.bw.edu/assets/stories/2022/fall/music-therapy-payton-cole-playing-guitar.jpg",
  },
  {
    id: "3",
    filmName: "Dance Jam",
    category: "Dance Therapy",
    filmLength: "1h 55m",
    image:
      "https://scaringelladance.files.wordpress.com/2019/07/img_2049.jpg?w=525",
  },
  {
    id: "4",
    filmName: "How to Play Chess",
    category: "Critical Thinking",
    filmLength: "1h 55m",
    image:
      "https://static.toiimg.com/thumb/msid-102827471,width-1280,height-720,resizemode-4/102827471.jpg",
  },
  {
    id: "5",
    filmName: "Master the Cube",
    category: "Critical Thinking",
    filmLength: "1h 55m",
    image:
      "https://wpvip.edutopia.org/wp-content/uploads/2022/10/rubiks_FINAL.jpg?w=2880&quality=85",
  },
];
