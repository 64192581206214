import { baseApi } from "./baseApi";
import {
  ContinueWatching,
  ContinueWatchingQueryParams,
  ContinueWatchingCreatePayload,
  ContinueWatchingUpdatePayload,
} from "../../types";
import {
  GET_CONTINUE_WATCHING,
  CREATE_CONTINUE_WATCHING,
  UPDATE_CONTINUE_WATCHING,
  DELETE_CONTINUE_WATCHING,
} from "../../constants/endpoints";

export const continueWatchingApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getContinueWatching: builder.query<
      { data: ContinueWatching[]; count: number },
      ContinueWatchingQueryParams
    >({
      query: (params) => ({
        url: GET_CONTINUE_WATCHING,
        method: "GET",
        params,
      }),
      providesTags: ["ContinueWatching"]
    }),
    createContinueWatching: builder.mutation<
      ContinueWatching,
      ContinueWatchingCreatePayload
    >({
      query: (payload) => ({
        url: CREATE_CONTINUE_WATCHING,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["ContinueWatching"]
    }),
    updateContinueWatching: builder.mutation<
      ContinueWatching,
      { id: string; payload: ContinueWatchingUpdatePayload }
    >({
      query: ({ id, payload }) => ({
        url: `${UPDATE_CONTINUE_WATCHING}/${id}`,
        method: "PATCH",
        body: payload,
      }),
      invalidatesTags: ["ContinueWatching"]
    }),
    deleteContinueWatching: builder.mutation<
      { success: boolean; id: string },
      string
    >({
      query: (id) => ({
        url: `${DELETE_CONTINUE_WATCHING}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ContinueWatching"]
    }),
  }),
});

export const {
  useGetContinueWatchingQuery,
  useLazyGetContinueWatchingQuery,
  useCreateContinueWatchingMutation,
  useUpdateContinueWatchingMutation,
  useDeleteContinueWatchingMutation,
} = continueWatchingApi;
