import React, { useState } from "react";
import {
  Modal,
  Box,
  Grid,
  Theme,
  Button,
  useTheme,
  useMediaQuery,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import StyledTextField from "../Input";

const style = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

interface PasswordModalProps {
  open: boolean;
  onClose: () => void;
  onPasswordSubmit: (password: string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  modalTitle: {
    marginBottom: theme.spacing(2),
    textAlign: "center",
  },
  submitButton: {
    marginTop: theme.spacing(3),
  },
}));

const PasswordModal: React.FC<PasswordModalProps> = ({
  open,
  onClose,
  onPasswordSubmit,
}) => {
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const classes = useStyles();
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const { t } = useTranslation();

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfirmPassword(event.target.value);
  };

  const handleSubmit = () => {
    if (password !== confirmPassword) {
      alert("Passwords do not match!");
      return;
    }
    onPasswordSubmit(password);
    setConfirmPassword("");
    setPassword("");
    onClose(); // Close modal after submission
  };

  const handleClose = () => {
    setConfirmPassword("");
    setPassword("");
    onClose(); // Close modal after submission
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{ ...style, width: isMdUp ? "auto" : "90%" }}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          className={classes.modalTitle}
        >
          Enter Your Password
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <StyledTextField
              fullWidth
              label="Password"
              type="password"
              autoComplete="new-password"
              value={password}
              onChange={handlePasswordChange}
            />
          </Grid>
          <Grid item xs={12}>
            <StyledTextField
              fullWidth
              label="Confirm Password"
              type="password"
              autoComplete="new-password"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
            />
          </Grid>
        </Grid>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          className={classes.submitButton}
          fullWidth
          sx={{textTransform: "none"}}
        >
          Verify
        </Button>
      </Box>
    </Modal>
  );
};

export default PasswordModal;
