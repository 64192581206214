type TrafficSource = "link" | "search" | "social media";

enum threshold {
  short = 300,
  long = 1800,
}

enum thresholdReason {
    view = "view",
    complete = "complete"
}

// Helper function to determine the device type
export const getDeviceType = (): "mobile" | "desktop" | "tablet" | "tv" => {
  const userAgent = navigator.userAgent.toLowerCase();
  if (/mobile|android|iphone|ipad/.test(userAgent)) {
    return "mobile";
  } else if (/tablet|ipad/.test(userAgent)) {
    return "tablet";
  } else if (/smart-tv|hbbtv|netcast|tizen|webos/.test(userAgent)) {
    return "tv";
  } else {
    return "desktop";
  }
};

// Helper function to determine the platform
export const getPlatform = ():
  | "firestick"
  | "ios"
  | "android"
  | "chrome"
  | "safari"
  | string => {
  const userAgent = navigator.userAgent.toLowerCase();
  if (/firetv|firestick/.test(userAgent)) {
    return "firestick";
  } else if (/android/.test(userAgent)) {
    return "android";
  } else if (/iphone|ipad/.test(userAgent)) {
    return "ios";
  } else if (/chrome/.test(userAgent)) {
    return "chrome";
  } else if (/safari/.test(userAgent) && !/chrome/.test(userAgent)) {
    return "safari";
  } else {
    return "web";
  }
};

export const calculateThreshold = (duration: number, reason: thresholdReason) => {
  if (reason === thresholdReason.view) {
    return duration * 0.2
  } else if (reason === thresholdReason.complete) {
    return duration * 0.7
  }
};

/**
 * Utility function to determine the traffic source for video view.
 *
 * @param referrer - The referring URL or identifier to check where the user came from.
 * @param searchQueryPresent - A flag indicating whether the user used the search functionality.
 * @returns TrafficSource - The source of traffic ('link', 'search', or 'social media').
 */
export const determineTrafficSource = (
  referrer: string | null,
  searchQueryPresent: boolean
): TrafficSource => {
  // If the user came from within the app by clicking a video thumbnail
  if (referrer && referrer.includes(window.location.origin)) {
    return "link";
  }

  // If the user used a search function within the app
  if (searchQueryPresent) {
    return "search";
  }

  // If the referrer is a known social media site
  const socialMediaPlatforms = [
    "facebook.com",
    "twitter.com",
    "instagram.com",
    "linkedin.com",
  ];
  if (
    referrer &&
    socialMediaPlatforms.some((platform) => referrer.includes(platform))
  ) {
    return "social media";
  }

  // Default to 'link' if no specific source is detected
  return "link";
};

// 1. Scenario 1: User clicked a video thumbnail from within the app
// In this case, referrer should contain the URL of your app, and searchQueryPresent is false (assuming they did not use search).
// const referrer = window.location.origin; // Simulates clicking a video thumbnail within the app
// const searchQueryPresent = false; // Not using search functionality
// const trafficSource = determineTrafficSource(referrer, searchQueryPresent);
// console.log(trafficSource); // Expected output: 'link'

// 2. Scenario 2: User found the video through a search engine
// Here, referrer will be a search engine URL, and searchQueryPresent is true.
// const referrer = 'https://www.google.com/search?q=example+video'; // Simulates a search engine referrer
// const searchQueryPresent = true; // User used search functionality within the app
// const trafficSource = determineTrafficSource(referrer, searchQueryPresent);
// console.log(trafficSource); // Expected output: 'search'

// 3. Scenario 3: User clicked a link shared on social media
// In this scenario, referrer should be a known social media URL, and searchQueryPresent is false.
// const referrer = 'https://www.facebook.com/examplepost'; // Simulates a social media referrer
// const searchQueryPresent = false; // Not using search functionality
// const trafficSource = determineTrafficSource(referrer, searchQueryPresent);
// console.log(trafficSource); // Expected output: 'social media'
