import "./i18n";
import { ThemeProvider } from "@mui/material/styles";
import { AlertProvider } from "./contexts/AlertContext";
import { SnackbarProvider } from "notistack";
import { lightTheme, darkTheme } from "./constants/themes";
import { useThemeMode } from "./hooks/useThemeMode";
import { Router } from "./router";
import { Levels } from "./types";

function App() {
  return <ThemedApp />;
}

// This is the ThemedApp component which applies the theme
function ThemedApp() {
  const isDarkMode = useThemeMode(Levels.app); // Hook to determine if dark mode is enabled

  return (
    <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
      <SnackbarProvider maxSnack={3} autoHideDuration={3500}>
        <AlertProvider>
          <Router />
        </AlertProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
