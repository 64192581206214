import { baseApi } from "./baseApi";
import {
  LoginPayload,
  LoginResponse,
  SignUpPayload,
  SignUpResponse,
  RecoverPasswordPayload,
  RecoverPasswordResponse,
  Company,
} from "../../types";
import {
  GET_NEW_ACCESS_TOKEN_ENDPOINT,
  LOGIN_USER_ENDPOINT,
  RECOVER_PASSWORD_ENDPOINT,
  SIGNUP_USER_ENDPOINT,
} from "../../constants/endpoints";
import { updateUser } from "../state/userSlice";

export const authApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponse, LoginPayload>({
      query: (credentials) => ({
        url: LOGIN_USER_ENDPOINT,
        method: "POST",
        body: credentials,
        params: { m: 1 },
      }),
    }),

    signup: builder.mutation<SignUpResponse, SignUpPayload>({
      query: (credentials) => ({
        url: SIGNUP_USER_ENDPOINT,
        method: "POST",
        body: credentials,
      }),
    }),
    recoverPassword: builder.mutation<
      RecoverPasswordResponse,
      RecoverPasswordPayload
    >({
      query: (params) => ({
        url: RECOVER_PASSWORD_ENDPOINT,
        method: "POST",
        params,
      }),
    }),
    getAccessToken: builder.mutation<{ access_token: string }, void>({
      query: () => ({
        url: GET_NEW_ACCESS_TOKEN_ENDPOINT,
        method: "POST",
        // The HTTP-only cookie with the refresh token is automatically included by the browser
      }),
    }),
  }),
  overrideExisting: false, // This is optional, set true if you want to override existing endpoints from baseApi
});

export const {
  useLoginMutation,
  useSignupMutation,
  useRecoverPasswordMutation,
  useGetAccessTokenMutation,
} = authApi;
