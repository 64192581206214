import { useCallback } from "react";
import {
  useGetContinueWatchingQuery,
  useCreateContinueWatchingMutation,
  useDeleteContinueWatchingMutation,
  useLazyGetContinueWatchingQuery,
  useUpdateContinueWatchingMutation,
} from "../../redux/api/continueWatchingApi";
import {
  ContinueWatchingCreatePayload,
  ContinueWatchingUpdatePayload,
} from "../../types";

export const useContinueWatching = (programId: string) => {
  const {
    data: continueWatchingData,
    error: fetchError,
    isLoading: isFetching,
  } = useGetContinueWatchingQuery({
    program_id: programId,
    page_number: 1,
    page_size: 10,
    sort: "desc",
    field: "created_at",
  });

  const [fetchVideoContinueWatching] = useLazyGetContinueWatchingQuery();

  const [
    createContinueWatching,
    { error: createError, isLoading: isCreating },
  ] = useCreateContinueWatchingMutation();

  const [
    updateContinueWatching,
    { error: updateError, isLoading: isUpdating },
  ] = useUpdateContinueWatchingMutation();

  const [
    deleteContinueWatching,
    { error: deleteError, isLoading: isDeleting },
  ] = useDeleteContinueWatchingMutation();

  // Encapsulate create logic
  const create = useCallback(
    async (payload: ContinueWatchingCreatePayload) => {
      await createContinueWatching(payload);
    },
    [createContinueWatching]
  );

  // Encapsulate update logic
  const update = useCallback(
    async (id: string, payload: ContinueWatchingUpdatePayload) => {
      await updateContinueWatching({ id, payload });
    },
    [updateContinueWatching]
  );

  //Encapsulate delete logic
  const remove = useCallback(
    async (id: string) => {
      await deleteContinueWatching(id);
    },
    [deleteContinueWatching]
  );

  return {
    continueWatchingData,
    fetchError,
    isFetching,
    create,
    createError,
    isCreating,
    update,
    updateError,
    isUpdating,
    remove,
    deleteError,
    isDeleting,
    fetchVideoContinueWatching, // New function to fetch specific video continue watching data
  }
};
