import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ContinueWatchingCard from "./ContinueWatchingCard"; // Adjust the import path as needed
import { SwiperSlide } from "swiper/react";
import SwiperScroll from "../../../../components/SwiperScroll";
import useBreakpoints from "../../../../hooks/useBreakPoints";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Video } from "../../../../types";
import {
  useGetVideoByIdQuery,
  useLazyGetVideoByIdQuery,
} from "../../../../redux/api/videoApi";
import { ContinueWatchingSkeletonCard } from "../../../../components/Skeletons/ContinueWatchingSkeletonCard";
import { Skeleton } from "@mui/material";
import { ContinueWatching as CW } from "../../../../types";

interface ContinueWatchingProps {
  continueWatchingData: {
    data: CW[];
    count: number;
  };
}

const ContinueWatching = ({ continueWatchingData }: ContinueWatchingProps) => {
  const { isXs, isSm, isMd, isLg, isXl } = useBreakpoints();
  const { currentProgram } = useSelector((state: RootState) => state.program);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [getVideoById, { data, error, isLoading: isLoadingVideo }] =
    useLazyGetVideoByIdQuery();

  // State to hold the fetched video details
  const [videos, setVideos] = useState<
    { video: Video; progress: number; continueWatchingId: string }[]
  >([]);

  useEffect(() => {
    const fetchVideoDetails = async () => {
      if (continueWatchingData && continueWatchingData.count > 0) {
        const fetchedVideos = await Promise.all(
          continueWatchingData.data.map(async (film) => {
            const { data: videoData } = await getVideoById(
              film.video_id
            ).unwrap();
            return {
              video: videoData,
              progress: film.current_position,
              continueWatchingId: film.id,
            };
          })
        );
        setVideos(fetchedVideos);
      }
    };

    fetchVideoDetails();
  }, [continueWatchingData]);

  const slidesPerView = isXs
    ? 1.5
    : isSm
    ? 3
    : isMd
    ? 3
    : isLg
    ? 4
    : isXl
    ? 4.5
    : 1.4;

  return (
    <>
      {isLoadingVideo ? (
        <Skeleton variant="text" width={80} />
      ) : (
        <h2 style={{ marginBottom: 2, color: "white", fontWeight: "normal" }}>
          {t("HomePage.ContinueWatchingTitle")}
        </h2>
      )}
      <SwiperScroll slidesPerView={slidesPerView}>
        {isLoadingVideo
          ? Array.from(new Array(4)).map((_, index) => (
              <SwiperSlide key={index}>
                <ContinueWatchingSkeletonCard />
              </SwiperSlide>
            ))
          : videos.map((film) => (
              <SwiperSlide key={film.video?.id}>
                {film.video ? (
                  <ContinueWatchingCard
                    key={film.video?.id}
                    id={film.video?.id}
                    video={film.video}
                    progress={film.progress}
                    total={film.video?.medias[0]?.length}
                    continueWatchingId={film.continueWatchingId}
                  />
                ) : null}
              </SwiperSlide>
            ))}
      </SwiperScroll>
    </>
  );
};

export default ContinueWatching;
