import React, { useState } from "react";
import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Chip,
  useTheme,
} from "@mui/material";
import {
  useGetSubscriptionQuery,
  useUnsubscribeSubscriptionMutation,
} from "../../../../redux/api/subscriptionApi";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import useBreakpoints from "../../../../hooks/useBreakPoints";
import { useAlert } from "../../../../contexts/AlertContext";
import { useTranslation } from "react-i18next";

const SubscriptionSettings = () => {
  const subscriptionId = useSelector(
    (state: RootState) => state.user.user.stripe?.subscription
  );
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const { isXs, isSm, isMd, isLg, isXl } = useBreakpoints();
  const theme = useTheme();
  const { error, success } = useAlert();
  const { t } = useTranslation();

  // Fetch subscription details if subscriptionId is defined
  const { data: subscription, isLoading } = useGetSubscriptionQuery(
    subscriptionId || ""
  );

  // Mocking the unsubscribing mutation
  const [unsubscribe, { isLoading: isUnsubscribing }] =
    useUnsubscribeSubscriptionMutation();

  const handleUnsubscribe = async () => {
    // Perform the unsubscribe mutation if subscriptionId is defined
    if (subscriptionId) {
      try {
        await unsubscribe(subscriptionId).unwrap();
        success(t("Settings.SubscriptionSettings.SuccessUnsubscribe"));
      } catch (err) {
        error(t("Settings.SubscriptionSettings.ErrorUnsubscribe"));
      }
      setShowConfirmationDialog(false); // Close the confirmation dialog
    }
  };

  if (isLoading) {
    return <div>{t("Settings.SubscriptionSettings.LoadingMessage")}</div>;
  }

  // Replace with the mock subscription if the actual subscription is not available
  const displaySubscription = subscription?.data;

  return (
    <div style={{ width: "100%" }}>
      <Typography variant="h4">{t("Settings.SubscriptionSettings.Title")}</Typography>

      <div style={{ display: "flex", marginTop: 10, width: "100%" }}>
        <Typography variant="body1" mr={1}>
        {t("Settings.SubscriptionSettings.Status")}
        </Typography>
        <Typography
          variant="body1"
          style={{
            color: displaySubscription?.status === "active" ? "green" : "red",
          }}
        >
          {displaySubscription?.status}
        </Typography>
      </div>

      <div style={{display: "flex", gap: 5}}>
        <Typography variant="body1" gutterBottom>
        {t("Settings.SubscriptionSettings.Starts")}{" "}
          <Chip label={formatDate(displaySubscription?.starts_at || "")} />
        </Typography>

        <Typography variant="body1">
        {t("Settings.SubscriptionSettings.Expires")}{" "}
          <Chip label={formatDate(displaySubscription?.expires_at || "")} />
        </Typography>
      </div>

      {displaySubscription?.canceled_at && (
        <Typography variant="body1">
          {t("Settings.SubscriptionSettings.CanceledAt")} {formatDate(displaySubscription.canceled_at)}
        </Typography>
      )}

      <Button
        onClick={() => setShowConfirmationDialog(true)}
        variant="contained"
        type="submit"
        fullWidth
        disabled={isUnsubscribing}
        color="primary"
        size="large"
        sx={{
          fontSize: isMd || isLg || isXl ? "1rem" : "inherit",
          background: "#EC674C",
          opacity: 1,
          paddingX: theme.spacing(6),
          paddingy: theme.spacing(1),
          my: theme.spacing(4),
          textTransform: "none",
          borderRadius: isMd || isLg || isXl ? "40px" : "30px",
          "&:hover": {
            background: "#EC674C", // Change background color on hover
          },
        }}
      >
        {t("Settings.SubscriptionSettings.UnsubscribeButton")}
      </Button>

      {/* Confirmation Dialog */}
      <Dialog
        open={showConfirmationDialog}
        onClose={() => setShowConfirmationDialog(false)}
      >
        <DialogTitle>{t("Settings.SubscriptionSettings.ConfirmationTitle")}</DialogTitle>
        <DialogContent>
          <Typography>
          {t("Settings.SubscriptionSettings.ConfirmationMessage")}{" "}
            {formatDate(displaySubscription?.expires_at || "")}.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setShowConfirmationDialog(false)}
            color="primary"
          >
            {t("Settings.SubscriptionSettings.CancelButton")}
          </Button>
          <Button
            onClick={handleUnsubscribe}
            color="primary"
            variant="contained"
          >
            {t("Settings.SubscriptionSettings.UnsubscribeButton")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

// Function to format date as dd/mm/yyyy
const formatDate = (dateString: string | number | Date) => {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

export default SubscriptionSettings;
