// components/Skeletons/RecommendationsSkeleton.jsx
import React from "react";
import { Box, Skeleton, Theme, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import useBreakpoints from "../../hooks/useBreakPoints";

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(15),
    },
  },
}));

const RecommendationsSkeleton = () => {
  const theme = useTheme();
  const classes = useStyles();
  const { isXs, isSm, isMd, isLg, isXl } = useBreakpoints();

  const skeletons = Array.from(new Array(isLg || isXl ? 2 : 1)); // Adjust the number of skeleton cards as needed
  const cardHeight = isXl ? 302 : isLg ? 202 : isMd ? 252 : isSm ? 202 : 202;
  const cardWidth = isXl ? 790 : isLg ? 590 : isMd ? 475 : isSm ? 395 : 300;
  return (
    <div className={classes.container}>
      <Skeleton
        variant="text"
        width="20%"
        height={30}
        sx={{ marginBottom: theme.spacing(2) }}
      />
      <Box sx={{ display: "flex", gap: theme.spacing(2), overflowX: "hidden", overflowY: "hidden" }}>
        {skeletons.map((_, index) => (
          <Skeleton
            key={index}
            variant="rectangular"
            width= {cardWidth}
            sx={{
              height: cardHeight,
              borderRadius: "10px",
              position: "relative",
            }}
          >
              <Skeleton variant="text" width="60%" height={30} />
              <Skeleton
                variant="text"
                width="40%"
                height={20}
                sx={{ marginTop: theme.spacing(1) }}
              />
              <Skeleton variant="rectangular" width="100%" height="100%"  />
          </Skeleton>
        ))}
      </Box>
    </div>
  );
};

export default RecommendationsSkeleton;
