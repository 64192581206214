import React, { useState } from "react";
import {
  FormControlLabel,
  List,
  ListItemText,
  Switch,
  Typography,
} from "@mui/material";
import { StyledListItemButton } from "../../../../components/ListButton";
import { languages } from "../../../../constants/constants";
import CheckIcon from "@mui/icons-material/Check";
import { ListItemCustom } from "../..";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import useBreakpoints from "../../../../hooks/useBreakPoints";

type Subtitle = {
  enabled: boolean;
  language: "en" | "fr" | "es";
};

interface SubtitleSettingsProps {
  subtitle: Subtitle;
  onSubtitleChange: (subtitle: Subtitle) => void;
}

const SubtitleSettings = ({
  subtitle,
  onSubtitleChange,
}: SubtitleSettingsProps) => {
  const [selected, setSelected] = useState(false);
  const { isXs, isSm, isMd, isLg, isXl } = useBreakpoints();

  const containerWidth = isXs || isSm ? "90%" : isMd || isLg ? "70%" : "60%";

  const handleListItemClick = (index: number) => {
    setSelected(!selected);
  };

  return (
    <div style={{ width: containerWidth }}>
      <Typography variant="h4" mb={4}>
        Subtitles
      </Typography>
      <FormControlLabel
        value="top"
        control={
          <Switch
            color="primary"
            value={subtitle.enabled}
            onChange={(e, checked) =>
              onSubtitleChange({ ...subtitle, enabled: checked })
            }
          />
        }
        sx={{ width: "100%", justifyContent: "space-between", margin: 0 }}
        label="Subtitles"
        labelPlacement="start"
      />
      <List sx={{ width: "100%" }}>
        <StyledListItemButton
          selected={selected}
          onClick={() => handleListItemClick(1)}
          disabled={!subtitle.enabled}
        >
          <ListItemCustom
            primary="Subtitles Language"
            secondary={
              subtitle.language === "en"
                ? "English"
                : subtitle.language === "fr"
                ? "French"
                : "Spanish"
            }
          />
          <ArrowForwardIosIcon />
        </StyledListItemButton>
      </List>

      {selected && (
        <List sx={{ width: "100%" }}>
          {languages.map((language) => (
            <StyledListItemButton
              key={language.code}
              selected={subtitle.language === language.name}
              onClick={() =>
                onSubtitleChange({ ...subtitle, language: language.code })
              }
              disabled={!subtitle.enabled}
            >
              <ListItemText primary={language.name} />
              {subtitle.language === language.name && <CheckIcon />}
            </StyledListItemButton>
          ))}
        </List>
      )}
    </div>
  );
};

export default SubtitleSettings;
