import React from 'react';
import { Box, IconButton, Theme, Tooltip } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { makeStyles } from '@mui/styles';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import useBreakpoints from '../../hooks/useBreakPoints';
import SliderWrapper from '../SliderWrapper';
import ThumbnailSkeleton from './ThumbnailSkeleton';

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    marginTop: theme.spacing(10),
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(15),
    },
  },
  playlistTitle: {
    display: "flex",
    alignItems: "center",
    fontWeight: "normal"
  },
}));

const PlaylistSkeleton = () => {
  const classes = useStyles();
  const { isXs, isSm, isMd, isLg, isXl } = useBreakpoints();

  const slidesPerView = isXs
    ? 1.5
    : isSm
    ? 3
    : isMd
    ? 3
    : isLg
    ? 4.2
    : isXl
    ? 4.5
    : 1;

  return (
    <div className={classes.container}>
      <div className={classes.playlistTitle}>
        <Skeleton variant="text" width={200} height={40} />
        <Skeleton variant="circular" width={24} height={24} />
        <Skeleton variant="circular" width={24} height={24} />
      </div>
      <SliderWrapper slidesPerView={slidesPerView}>
        {Array.from(new Array(5)).map((_, index) => (
          <ThumbnailSkeleton key={index} />
        ))}
      </SliderWrapper>
    </div>
  );
};

export default PlaylistSkeleton;
