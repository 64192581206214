import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  useTheme,
  CircularProgress,
} from "@mui/material";
import StyledTextField from "../Input";
import {
  useCreatePlaylistMutation,
  useUpdatePlaylistMutation,
} from "../../redux/api/playlistApi";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useTranslation } from "react-i18next";

interface CreatePlaylistDialogProps {
  open: boolean;
  onClose: () => void;
  editingMode?: boolean;
  initialData?: {
    id?: string; // ID is required for editing
    name: string;
    description: string;
  };
}

const CreatePlaylistDialog: React.FC<CreatePlaylistDialogProps> = ({
  open,
  onClose,
  editingMode = false,
  initialData,
}) => {
  const theme = useTheme();
  const currentProgram = useSelector(
    (state: RootState) => state.program.currentProgram
  );

  const [playlistName, setPlaylistName] = useState("");
  const [playlistDescription, setPlaylistDescription] = useState("");

  const [createPlaylist, { isLoading: isCreating }] =
    useCreatePlaylistMutation();
  const [updatePlaylist, { isLoading: isUpdating }] =
    useUpdatePlaylistMutation();

  const { t } = useTranslation();

  useEffect(() => {
    if (editingMode && initialData) {
      setPlaylistName(initialData.name);
      setPlaylistDescription(initialData.description);
    } else {
      setPlaylistName("");
      setPlaylistDescription("");
    }
  }, [editingMode, initialData]);

  const handleAction = async () => {
    const playlistData = {
      name: playlistName,
      description: playlistDescription,
    };

    try {
      if (editingMode && initialData?.id) {
        await updatePlaylist({
          playlistId: initialData.id,
          ...playlistData,
        }).unwrap();
      } else {
        await createPlaylist({
          ...playlistData,
          program_id: currentProgram?.id!,
        }).unwrap();
      }
      onClose();
    } catch (error) {
      console.error("Error:", error);
      // Handle error
    }
  };

  const isProcessing = editingMode ? isUpdating : isCreating;

  return (
    <Dialog open={open} onClose={onClose} maxWidth={"sm"}>
      <DialogTitle>
        {editingMode
          ? t("CreatePlaylistDialog.DialogTitleEdit")
          : t("CreatePlaylistDialog.DialogTitleCreate")}
      </DialogTitle>
      <DialogContent>
        <StyledTextField
          autoFocus
          margin="dense"
          label={t("CreatePlaylistDialog.PlaylistNameLabel")}
          type="text"
          fullWidth
          variant="outlined"
          value={playlistName}
          onChange={(e) => setPlaylistName(e.target.value)}
        />
        <StyledTextField
          margin="dense"
          label={t("CreatePlaylistDialog.PlaylistDescriptionLabel")}
          type="text"
          fullWidth
          variant="outlined"
          value={playlistDescription}
          onChange={(e) => setPlaylistDescription(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          disabled={!playlistName || isProcessing}
          sx={{
            fontSize: ["0.5rem", null, null, "1rem"],
            background: "#EC674C",
            mx: theme.spacing(2),
            textTransform: "none",
            borderRadius: theme.shape.borderRadius,
            "&:hover": {
              background: "#EC674C",
            },
          }}
          onClick={handleAction}
        >
          {isProcessing ? (
            <CircularProgress />
          ) : editingMode ? (
            t("CreatePlaylistDialog.UpdateButton")
          ) : (
            t("CreatePlaylistDialog.CreateButton")
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreatePlaylistDialog;
